import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InkblotLogo from 'images/SignUp/inkblot-logo.svg';
import SVG from 'react-inlinesvg';
import { reduxForm, Field } from 'redux-form/immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  COUNTRY,
  MARKETING_URL,
  OTHER_THERAPY_URL,
  PRACTICE_URL,
} from 'utils/environment';

// squid
import { SqTextInputWithLabel } from 'components/SqTextInput/TextInput';
import RadioCard from 'components/SqRadioCard/RadioCard';
import SqAlert from 'components/SqAlert/Alert';
import SqButton from 'components/SqButton/SqButton';
import PropTypes from 'prop-types';
import LanguageSelector from 'containers/LanguageSelector';
import { minTabletSize, phoneSize, teenyWeenySize } from '../../global-styles';
import messages from './messages';
import signIn from './SignIn';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 30px;
  min-height: 100vh;
  z-index: 100;
  max-height: 100vh;
  overflow-y: scroll;

  @media (min-width: ${teenyWeenySize}) {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    align-items: center;
  }
  @media (min-width: ${phoneSize}) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  @media (min-width: ${minTabletSize}) {
    padding-left: 11%;
    padding-right: 10px;
    width: 45%;
    align-items: left;
  }
`;

const SignupFormElementsWrapper = styled.div`
  max-width: 374px;
  width: 100%;
`;

const AlreadyHaveAnAccountWrapper = styled.div`
  margin-top: 10px;
  > * {
    margin-top: 8px;
  }
`;

const StyledLabel = styled.span`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #696e82;
  margin-bottom: 8px;
`;

const CountrySelectorButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  margin-bottom: 30px;
`;
const StyledRadioCard = styled(RadioCard)`
  width: 143px;
  height: 44px;
`;

const StyledLink = styled(Link)`
  color: #5d70ba;
`;
const StyledFakeLink = styled.span`
  color: #5d70ba;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 374px;
  width: 100%;
  margin-bottom: 45px;
`;
const PageTitle = styled.span`
  font-family: 'P22 Mackinac Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  color: #282d40;
`;

const StyledForm = styled.form`
  > * {
    margin-bottom: 30px;
    max-width: 374px;
    width: 100%;
  }
`;
const SignInForm = props => {
  const { intl } = props;
  const { locale } = intl;
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY);
  const [loading, setLoading] = useState();
  const [loginError, setLoginError] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(
    () => {
      if (selectedCountry !== COUNTRY) {
        window.location.href = `${OTHER_THERAPY_URL}/signin`;
      }
    },
    [selectedCountry],
  );

  const onSubmit = async values => {
    const { email, password } = values.toJS();
    setLoading(true);
    const res = await signIn({
      email,
      password,
      locale,
      source: 'therapy',
    });
    setLoading(false);
    if (res && res.error) {
      setLoginError(res.error);
    }
  };

  return (
    <Panel>
      <StyledHeader>
        <SVG src={InkblotLogo} alt="right icon" />
        <LanguageSelector locale={(locale || 'EN').toUpperCase()} />
      </StyledHeader>
      <SignupFormElementsWrapper>
        <PageTitle>
          <FormattedMessage {...messages.title} />
        </PageTitle>
        <AlreadyHaveAnAccountWrapper>
          <p>
            <FormattedMessage
              {...messages.dontHaveAcc}
              values={{
                link: (
                  <StyledLink to="signup">
                    <FormattedMessage {...messages.signup} />
                  </StyledLink>
                ),
              }}
            />
          </p>
          <p>
            <text tabIndex={0}>
              <FormattedMessage {...messages.companySignup} />
            </text>
          </p>
        </AlreadyHaveAnAccountWrapper>
        <hr style={{ width: '100px', marginLeft: '0', marginRight: 'auto' }} />
        <div style={{ marginBottom: '8px' }}>
          <StyledLabel>
            <FormattedMessage {...messages.country} />
          </StyledLabel>
        </div>
        <CountrySelectorButtonWrapper>
          <StyledRadioCard
            onClick={() => {
              setSelectedCountry('CA');
            }}
            initValue={COUNTRY === 'CA'}
            label="Canada"
            style={{ marginRight: '14px' }}
          />
          <StyledRadioCard
            onClick={() => setSelectedCountry('US')}
            label={<FormattedMessage {...messages.america} />}
            initValue={COUNTRY === 'US'}
          />
        </CountrySelectorButtonWrapper>
        <StyledForm onSubmit={props.handleSubmit(onSubmit)}>
          <Field
            component={SqTextInputWithLabel}
            name="email"
            label={<FormattedMessage {...messages.email} />}
            type="text"
          />
          <Field
            component={SqTextInputWithLabel}
            name="password"
            label={<FormattedMessage {...messages.password} />}
            type={passwordVisible ? 'text' : 'password'}
            rightIconName={passwordVisible ? 'Eye' : 'EyeOff'}
            rightIconOnclick={() => setPasswordVisible(!passwordVisible)}
            style={{ width: '100%' }}
            rightIconAriaLabel={
              passwordVisible ? 'Hide password' : 'Show password'
            }
          />
          <SqButton
            type="submit"
            disabled={loading || props.invalid}
            label={<FormattedMessage {...messages.title} />}
            style={{ width: '109px', height: '56px', marginBottom: '16px' }}
          />
        </StyledForm>
        <StyledFakeLink
          tabIndex="0"
          onClick={() => {
            window.location.href = `${MARKETING_URL}/forgot_password`;
          }}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              window.location.href = `${MARKETING_URL}/forgot_password`;
            }
          }}
        >
          <FormattedMessage {...messages.forgotPassword} />
        </StyledFakeLink>
        <div style={{ marginTop: '10px' }}>
          <StyledFakeLink
            tabIndex="0"
            onClick={() => {
              window.location.href = `${PRACTICE_URL}/signin`;
            }}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                window.location.href = `${PRACTICE_URL}/signin`;
              }
            }}
          >
            <FormattedMessage {...messages.ifYouAreACounsellor} />
          </StyledFakeLink>
        </div>
        {loginError && (
          <SqAlert
            type="critical"
            text={loginError}
            style={{ marginTop: '16px' }}
          />
        )}
        <SqAlert
          style={{ marginTop: '64px' }}
          type="infoCritical"
          text={
            COUNTRY === 'US' ? (
              `If you or someone you know is in crisis and requires immediate support, call the 988 Suicide & Crisis Lifeline or go to your nearest emergency room. Please know you are not alone. Support is available`
            ) : (
              <FormattedMessage {...messages.crisisAlert} />
            )
          }
        />
      </SignupFormElementsWrapper>
    </Panel>
  );
};
SignInForm.propTypes = {
  intl: PropTypes.object,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.object,
};
export default reduxForm({
  form: 'SignInForm',
  validate,
})(injectIntl(SignInForm));

function validate(values) {
  const errors = {};

  // for required fields
  if (!values.get('email')) {
    errors.email = (
      <FormattedMessage id="app.containers.signUp.signUpForm.errors.required" />
    );
  }

  if (!values.get('password')) {
    errors.password = (
      <FormattedMessage id="app.containers.signUp.signUpForm.errors.required" />
    );
  }
  // input validation
  const email = values.get('email');
  const password = values.get('password');

  if (email) {
    const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i;
    if (!validEmail.test(email)) {
      errors.email = (
        <FormattedMessage id="app.containers.Matching.Error.validEmail" />
      );
    }
  }

  if (password) {
    if (!password) {
      errors.password = (
        <FormattedMessage id="app.containers.signUp.signUpForm.errors.required" />
      );
      // eslint-disable-next-line camelcase
    }
  }

  return errors;
}
