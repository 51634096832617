/*
 * LanguageButton Messages
 *
 * This contains all the text for the LanguageButton component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.LanguageButton';

export default defineMessages({
  English: { id: `${scope}.English`, defaultMessage: 'English' },
  French: { id: `${scope}.French`, defaultMessage: 'French' },
  Arabic: { id: `${scope}.Arabic`, defaultMessage: 'Arabic' },
  Bengali: { id: `${scope}.Bengali`, defaultMessage: 'Bengali' },
  Bosnian: { id: `${scope}.Bosnian`, defaultMessage: 'Bosnian' },
  Cantonese: { id: `${scope}.Cantonese`, defaultMessage: 'Cantonese' },
  Croatian: { id: `${scope}.Croatian`, defaultMessage: 'Croatian' },
  Farsi: { id: `${scope}.Farsi`, defaultMessage: 'Farsi' },
  Filipino: { id: `${scope}.Filipino`, defaultMessage: 'Filipino' },
  Greek: { id: `${scope}.Greek`, defaultMessage: 'Greek' },
  German: { id: `${scope}.German`, defaultMessage: 'German' },
  Hebrew: { id: `${scope}.Hebrew`, defaultMessage: 'Hebrew' },
  Hindi: { id: `${scope}.Hindi`, defaultMessage: 'Hindi' },
  Hungarian: { id: `${scope}.Hungarian`, defaultMessage: 'Hungarian' },
  Italian: { id: `${scope}.Italian`, defaultMessage: 'Italian' },
  Japanese: { id: `${scope}.Japanese`, defaultMessage: 'Japanese' },
  Mandarin: { id: `${scope}.Mandarin`, defaultMessage: 'Mandarin' },
  Polish: { id: `${scope}.Polish`, defaultMessage: 'Polish' },
  Portuguese: { id: `${scope}.Portuguese`, defaultMessage: 'Portuguese' },
  Punjabi: { id: `${scope}.Punjabi`, defaultMessage: 'Punjabi' },
  Russian: { id: `${scope}.Russian`, defaultMessage: 'Russian' },
  Serbian: { id: `${scope}.Serbian`, defaultMessage: 'Serbian' },
  Spanish: { id: `${scope}.Spanish`, defaultMessage: 'Spanish' },
  Urdu: { id: `${scope}.Urdu`, defaultMessage: 'Urdu' },
  ASL: { id: `${scope}.ASL`, defaultMessage: 'ASL' },
  Macedonian: { id: `${scope}.Macedonian`, defaultMessage: 'Macedonian'},
  Somali: { id: `${scope}.Somali`, defaultMessage: 'Somali'},
  Marathi: { id: `${scope}.Marathi`, defaultMessage: 'Marathi'},
});
