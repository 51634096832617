/*
 *
 * LanguageSelector
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SqDropdownButton from 'components/SqDropdownButton/DropdownButton';
import { changeLocale } from '../LanguageProvider/actions';

const width = '74px';

const LanguageSelector = props => (
  <SqDropdownButton
    style={{ width }}
    hideIcon
    placeholder={props.locale || 'EN'}
    onChange={({ key }) => {
      props.changeLocale(key);
      localStorage.setItem('selectedLanguage', key);
    }}
    selections={[
      { key: 'en', value: 'EN', label: 'EN' },
      { key: 'fr', value: 'FR', label: 'FR' },
    ]}
    btnStyle={{
      width,
      outline: 'none',
      fontWeight: '600',
      display: 'flex',
      alignItem: 'center',
    }}
    menuStyle={{ width }}
  />
);

LanguageSelector.propTypes = {
  changeLocale: PropTypes.func,
  locale: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    changeLocale: value => dispatch(changeLocale(value)),
    dispatch,
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(LanguageSelector);
