/*
 * Preferences Messages
 *
 * This contains all the text for the Preferences container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Preferences';
export const matchingScope = 'app.containers.Matching';

export default defineMessages({
  preferences: {
    id: `${scope}.preferences.header`,
    defaultMessage: 'Preferences',
  },
  deleteData: {
    id: `${scope}.deleteData`,
    defaultMessage: 'I would like to request deletion of my personal data',
  },
  personalDetails: {
    id: `${scope}.PersonalDetails.personalDetails.header`,
    defaultMessage: 'Personal Details',
  },
  emergencyContact: {
    id: `${scope}.MyCareTeam.EmergencyContact.header`,
    defaultMessage: 'Emergency Contact',
  },
  billingDetails: {
    id: `${scope}.BillingDetails.billingDetails.Header`,
    defaultMessage: 'Billing Details',
  },
  modifyBillingDetails: {
    id: `${scope}.BillingDetails.billingDetails.button.modifyBillingDetails`,
    defaultMessage: 'Modify Billing Details',
  },
  emailPassword: {
    id: `${scope}.emailPassword.Header`,
    defaultMessage: 'Email & Password',
  },
  jumpTo: {
    id: `${scope}.JumpToMenu.subheader`,
    defaultMessage: 'Jump To:',
  },
  chipMyCareTeam: {
    id: `${scope}.JumpToMenu.Chip.myCareTeam`,
    defaultMessage: 'My Care Team',
  },
  chipBilling: {
    id: `${scope}.JumpToMenu.Chip.billing`,
    defaultMessage: 'Billing',
  },
  chipEmployeeWellness: {
    id: `${scope}.JumpToMenu.Chip.employeeWellness`,
    defaultMessage: 'Sponsored Hours',
  },
  chipInvite: {
    id: `${scope}.JumpToMenu.Chip.invite`,
    defaultMessage: 'Invite',
  },
  firstName: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.firstName`,
    defaultMessage: 'First Name',
  },
  lastName: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.lastName`,
    defaultMessage: 'Last Name',
  },
  preferredName: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.preferredName`,
    defaultMessage: 'Preferred Name',
  },
  dateOfBirth: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.dateOfBirth`,
    defaultMessage: 'Date Of Birth',
  },
  phoneNumber: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
  timezone: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.timezone`,
    defaultMessage: 'Time Zone',
  },
  address: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.address`,
    defaultMessage: 'Address',
  },
  street: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.street`,
    defaultMessage: 'Street Address',
  },
  alternateStreet: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.alternateStreet`,
    defaultMessage: 'Alternate Street',
  },
  cityTown: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.cityTown`,
    defaultMessage: 'City/Town',
  },
  province: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.province`,
    defaultMessage: 'Province/Territory',
  },
  state: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.state`,
    defaultMessage: 'State',
  },
  zone: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.zone`,
    defaultMessage: 'Zone',
  },

  zipCode: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.zipCode`,
    defaultMessage: 'Zip Code',
  },
  country: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.country`,
    defaultMessage: 'Country',
  },
  postalCode: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.postalCode`,
    defaultMessage: 'Postal Code',
  },
  updatePicture: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.button.updatePicture`,
    defaultMessage: 'Update Picture',
  },
  updateChanges: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.button.updateChanges`,
    defaultMessage: 'Update Changes',
  },
  updateEmail: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.button.updateEmail`,
    defaultMessage: 'Update Email',
  },
  enterCurrentEmail: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.enterCurrentEmail`,
    defaultMessage: 'Enter Current Email',
  },
  enterNewEmail: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.enterNewEmail`,
    defaultMessage: 'Enter New Email',
  },
  confirmNewEmail: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.confirmNewEmail`,
    defaultMessage: 'Confirm New Email',
  },
  enterCurrentPassword: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.enterCurrentPassword`,
    defaultMessage: 'Enter Current Password',
  },
  enterNewPassword: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.enterNewPassword`,
    defaultMessage: 'Enter New Password',
  },
  confirmNewPassword: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.subheader.confirmNewPassword`,
    defaultMessage: 'Confirm New Password',
  },
  updatePassword: {
    id: `${scope}.PersonalDetails.PersonalDetailsForm.button.updatePassword`,
    defaultMessage: 'Update Password',
  },
  profilePicture: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.profilePicture`,
    defaultMessage: 'Profile Picture',
  },
  reminders: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.reminders`,
    defaultMessage: 'Reminders',
  },
  smsReminders: {
    id: `${scope}.PersonalDetails.PersonalDetailsInformation.subheader.smsReminders`,
    defaultMessage:
      'I would like to receive an SMS reminder at 8am on the day of my session',
  },
  email: {
    id: `${scope}.EmailAndPassword.EmailPasswordInformation.subheader.email`,
    defaultMessage: 'Email',
  },
  password: {
    id: `${scope}.EmailAndPassword.EmailPasswordInformation.subheader.password`,
    defaultMessage: 'Password',
  },
  fullName: {
    id: `${scope}.EmergencyContact.EmergencyContactInformation.subheader.fullName`,
    defaultMessage: 'Full Name',
  },
  relationship: {
    id: `${scope}.EmergencyContact.EmergencyContactInformation.subheader.relationship`,
    defaultMessage: 'Relationship',
  },
  phoneNumberEmergency: {
    id: `${scope}.EmergencyContact.EmergencyContactInformation.subheader.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
  cardNumber: {
    id: `${scope}.BillingDetails.subheader.cardNumber`,
    defaultMessage: 'Card Number',
  },
  noCardFound: {
    id: `${scope}.BillingDetails.subheader.noCardFound`,
    defaultMessage: 'No card found',
  },
  cardSave: {
    id: `${scope}.BillingDetails.subheader.cardSave`,
    defaultMessage: 'Save Card',
  },
  employeeWellness: {
    id: `${scope}.EmployeeWellness.header.employeeWellness`,
    defaultMessage: 'Sponsored Hours',
  },
  remainingCredits: {
    id: `${scope}.EmployeeWellness.subheader.remainingCredits`,
    defaultMessage: 'Remaining Credits',
  },
  reimburseNoCredits: {
    id: `${scope}.EmployeeWellness.subheader.reimburseNoCredits`,
    defaultMessage:
      'Cost of services may be reimbursable by your benefit health plan.',
  },
  creditCount: {
    id: `${scope}.EmployeeWellness.chip.creditCount`,
    defaultMessage: '{credits} credits ({minutes} minutes)',
  },
  creditsExpire: {
    id: `${scope}.EmployeeWellness.body.creditsExpire`,
    defaultMessage:
      'When your hours expire, you will be billed for your following sessions until the hours renew',
  },
  atbCreditsExpire: {
    id: `${scope}.EmployeeWellness.body.atbCreditsExpire`,
    defaultMessage:
      'You’ll notice your total credits are shown above. When your credits expire, you can process any further sessions through your ATB Flex Benefit Plan until your credits renew. The cost of counselling is reimbursable under our Flex Benefit Plan.',
  },
  dependants: {
    id: `${scope}.EmployeeWellness.subheader.dependants`,
    defaultMessage: 'Dependants',
  },
  eligableDependantInvite: {
    id: `${scope}.EmployeeWellness.body.dependantsInvite`,
    defaultMessage: 'You are eligible to invite {count} dependant',
  },
  inviteDependant: {
    id: `${scope}.EmployeeWellness.button.inviteDependant`,
    defaultMessage: 'Invite Dependant',
  },
  inviteSomeoneToInkblot: {
    id: `${scope}.EmployeeWellness.header.inviteSomeoneToInkblot`,
    defaultMessage: 'Invite Someone to Inkblot',
  },
  giftOfFeelingBetter: {
    id: `${scope}.EmployeeWellness.body.giftOfFeelingBetter`,
    defaultMessage:
      'Share the gift of feeling better {br}{br}Use this form to invite a friend or family member to Inkblot and {thirtyMinutes}',
  },
  thirtyMinutes: {
    id: `${scope}.EmployeeWellness.body.thirtyMinutes`,
    defaultMessage: 'profitez de 30 minutes supplémentaires.',
  },
  maxAmountOfDependants: {
    id: `${scope}.InviteDependants.maxAmountOfDependants`,
    defaultMessage: 'Max amount of dependants',
  },
  yourDependantsInfo: {
    id: `${scope}.InviteDependants.yourDependantsInfo`,
    defaultMessage: 'Your dependants information:',
  },
  relationshipToYou: {
    id: `${scope}.InviteDependants.relationshipToYou`,
    defaultMessage: 'Relationship to you',
  },
  sendInvite: {
    id: `${scope}.InviteDependants.sendInvite`,
    defaultMessage: 'Send Invite',
  },
  crisisServices: {
    id: `${scope}.EmployeeWellness.crisisServices`,
    defaultMessage:
      'For crisis support, please call toll-free: 1-855-933-0103.',
  },
  pdTitle: {
    id: `${matchingScope}.PD.pdTitle`,
    defaultMessage: 'Parent / Guardian Details',
  },
  pdSubTitle: {
    id: `${matchingScope}.PD.pdSubTitle`,
    defaultMessage: 'This information is needed for safety and consent reasons',
  },
  pdFormLabel: {
    id: `${matchingScope}.PD.pdFormLabel`,
    defaultMessage: 'Parent / Guardian',
  },
  pdFirstNameLabel: {
    id: `${matchingScope}.PD.pdFirstNameLabel`,
    defaultMessage: 'First Name',
  },
  pdLastNameLabel: {
    id: `${matchingScope}.PD.pdLastNameLabel`,
    defaultMessage: 'Last Name',
  },
  pdRelationshipLabel: {
    id: `${matchingScope}.PD.pdRelationshipLabel`,
    defaultMessage: 'Relationship',
  },
  pdEmailLabel: {
    id: `${matchingScope}.PD.pdEmailLabel`,
    defaultMessage: 'Email Address',
  },
  pdPhoneNumberLabel: {
    id: `${matchingScope}.PD.pdPhoneNumberLabel`,
    defaultMessage: 'Phone Number',
  },
  pdAlternatePhoneNumberLabel: {
    id: `${matchingScope}.PD.pdAlternatePhoneNumberLabel`,
    defaultMessage: 'Alternate Phone Number',
  },
  pdAddressCheckBox: {
    id: `${matchingScope}.PD.pdAddressCheckBox`,
    defaultMessage: 'Address same as person seeking therapy',
  },
  pdAdditionalParentButton: {
    id: `${matchingScope}.PD.pdAdditionalParentButton`,
    defaultMessage: 'Add additional parents / guardians',
  },
  pdRemovalParentButton: {
    id: `${matchingScope}.PD.pdRemovalParentButton`,
    defaultMessage: 'Remove parent / guardian',
  },
  pdAddress: {
    id: `${matchingScope}.YD.address`,
    defaultMessage: 'Address 1',
  },
  pdAlternateStreet: {
    id: `${matchingScope}.YD.alternateStreet`,
    defaultMessage: 'Address 2',
  },
  pdCityTown: {
    id: `${matchingScope}.YD.cityTown`,
    defaultMessage: 'City/Town',
  },
  stateProvince: {
    id: `${matchingScope}.YD.stateProvince`,
    defaultMessage: 'Province/Territory/State',
  },
  pdPostalCode: {
    id: `${matchingScope}.YD.postalCode`,
    defaultMessage: 'Postal/Zip Code',
  },
  pdCountry: {
    id: `${matchingScope}.YD.country`,
    defaultMessage: 'Country',
  },
  pdZone: {
    id: `${matchingScope}.zone`,
    defaultMessage: 'Zone',
  },
  aptNumber: {
    id: `${matchingScope}.YD.aptNumber`,
    defaultMessage: 'Apartment, suite, unit, etc.',
  },
  streetAddress: {
    id: `${matchingScope}.YD.streetAddress`,
    defaultMessage: 'Street address',
  },
  verifyPhoneNumber: {
    id: `${matchingScope}.YD.verifyPhoneNumber`,
    defaultMessage: 'Verify phone number',
  },
});
