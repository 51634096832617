import React from 'react';
import { reduxForm } from 'redux-form/immutable';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';

import H1 from 'components/H1';
import H3 from 'components/H3';
import Button from 'components/Button';
import Body from 'components/Body';
import TermsAndConditions from 'containers/TermsAndConditions';
import { errorRed } from 'global-styles';
import P from 'components/P';
import errorMessages from 'components/ErrorDialog/messages';

import messages from './messages';

class TermsAndConditionsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { submissionError: false };
  }

  onSubmit = () => {
    const { submitForm, updateTermsConditions } = this.props;

    this.setState({ submissionError: false });
    updateTermsConditions(error => {
      if (!error) {
        submitForm();
      } else {
        this.setState({ submissionError: true });
      }
    });
  };

  renderConfirmationHeader = () => {
    const { company, intl } = this.props;

    const messageValues = {
      policy: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            intl.locale === 'fr'
              ? 'https://inkblot.zendesk.com/hc/fr-ca/articles/4402131907476-Politiques-d-annulation-et-d-absence'
              : 'https://inkblot.zendesk.com/hc/en-us/articles/4402131907476-Cancellation-and-absence-policies'
          }
          style={{ color: 'black' }}
        >
          {intl.locale === 'fr'
            ? 'politique d’annulation'
            : 'cancellation policy'}
        </a>
      ),
    };

    if (company && company.get('name') === 'Benevity') {
      return (
        <FormattedMessage
          {...messages.pleaseReadBenevity}
          values={messageValues}
        />
      );
    }

    return <FormattedMessage {...messages.pleaseRead} values={messageValues} />;
  };

  render() {
    const {
      handleSubmit,
      toggleDeclined,
      termsAndConditions,
      loading,
      tokenLoaded,
    } = this.props;
    const { submissionError } = this.state;
    const releaseDate = termsAndConditions
      ? moment(termsAndConditions.release_date).format('DD-MM-YYYY')
      : 'XX-XX-XXXX';

    return (
      <div>
        <H1>
          <FormattedMessage {...messages.updatedTC} />
        </H1>
        <H3>
          <FormattedMessage {...messages.lastRevised} /> {releaseDate}
        </H3>
        <Body className="contact-support" opacity="0.75">
          {this.renderConfirmationHeader()}{' '}
          <FormattedMessage {...messages.contactSupport} />
        </Body>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <TermsAndConditions
            consentType="TCs"
            style={{ margin: '20px 0' }}
            tokenLoaded={tokenLoaded}
          />
          <Button
            disabled={loading}
            label={messages.accept}
            secondary
            type="submit"
            style={{ marginTop: 0 }}
          />
          <Button
            label={messages.decline}
            onClick={toggleDeclined}
            tertiary
            style={{ marginTop: '20px' }}
          />
        </form>
        {submissionError && (
          <P color={errorRed}>
            <FormattedMessage {...errorMessages.generalErrorMessage} />
          </P>
        )}
      </div>
    );
  }
}

TermsAndConditionsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  toggleDeclined: PropTypes.func.isRequired,
  updateTermsConditions: PropTypes.func.isRequired,
  termsAndConditions: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  tokenLoaded: PropTypes.bool.isRequired,
  company: PropTypes.object,
  intl: intlShape,
};

const validate = values => {
  const errors = {};

  if (!values.get('read_tos')) {
    errors.read_tos = (
      <FormattedMessage {...messages.tncCheckBoxErrorMessages} />
    );
  }
  if (!values.get('indi_counsellors')) {
    errors.indi_counsellors = (
      <FormattedMessage {...messages.tncCheckBoxErrorMessages} />
    );
  }
  if (!values.get('confidential')) {
    errors.confidential = (
      <FormattedMessage {...messages.tncCheckBoxErrorMessages} />
    );
  }
  return errors;
};

export default reduxForm({
  form: 'TermsAndConditionsForm',
  validate,
})(injectIntl(TermsAndConditionsForm));
