import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SignIn';

export default defineMessages({
  title: {
    id: `${scope}.title`,
  },
  signup: {
    id: `${scope}.signup`,
    defaultMessage: "Don't have an account? {link}",
  },
  dontHaveAcc: {
    id: `${scope}.dontHaveAcc`,
  },
  companySignup: {
    id: `${scope}.companySignup`,
  },
  country: {
    id: `${scope}.country`,
  },
  email: {
    id: `${scope}.email`,
  },
  password: {
    id: `${scope}.password`,
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
  },
  crisisAlert: {
    id: `${scope}.crisisAlert`,
  },
  america: {
    id: `${scope}.america`,
  },
  ifYouAreACounsellor: {
    id: `${scope}.ifYouAreACounsellor`,
  },
});
