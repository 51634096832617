/**
 *
 * TopNavigationBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Map, fromJS } from 'immutable';

import Header from 'components/Header';
import { makeSelectLocation } from 'containers/App/selectors';
import { makeSelectUser } from 'containers/UserInformation/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { updateUser } from 'containers/UserInformation/actions';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { signOut } from 'utils/auth';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { setIntercomUser } from 'utils/intercomHelpers';

import { checkNewChatMessage } from './actions';
import { makeSelectNewMessageInfo } from './selectors';
import reducer from './reducer';
import saga from './saga';

/* eslint-disable react/prefer-stateless-function */
export class TopNavigationBar extends React.PureComponent {
  componentDidMount() {
    if (this.props.tokenLoaded) {
      this.props.checkNewChatMessage();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tokenLoaded !== this.props.tokenLoaded &&
      this.props.tokenLoaded
    ) {
      this.props.checkNewChatMessage();
    }
  }

  handleSignOut() {
    signOut();
  }

  handleChangeLocale = locale => {
    const { user } = this.props;

    this.props.updateUser({ locale }, () => {
      this.props.changeLocale(locale);
      setIntercomUser(user.id, user.email, user.first_name, locale);
    });
  };

  handleChangeTimezone = timezone => {
    this.props.updateUser({ timezone }, () => {
      window.location.reload(false);
    });
  };

  render() {
    const { location, user, newMessageInfo, locale } = this.props;
    const localeObject = fromJS({
      currentLocale: locale,
      changeLocale: this.handleChangeLocale,
    });
    const timezoneObject = fromJS({
      currentTimezone: user.get('timezone'),
      changeTimezone: this.handleChangeTimezone,
    });

    return (
      <Header
        user={user}
        location={location}
        newMessageInfo={newMessageInfo}
        handleSignOut={this.handleSignOut}
        localeObject={localeObject}
        timezoneObject={timezoneObject}
      />
    );
  }
}

TopNavigationBar.propTypes = {
  location: PropTypes.object,
  user: PropTypes.instanceOf(Map),
  newMessageInfo: PropTypes.instanceOf(Map),
  checkNewChatMessage: PropTypes.func.isRequired,
  locale: PropTypes.string,
  changeLocale: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  tokenLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  location: makeSelectLocation(),
  newMessageInfo: makeSelectNewMessageInfo(),
  locale: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    checkNewChatMessage: () => dispatch(checkNewChatMessage()),
    changeLocale: languageLocale => dispatch(changeLocale(languageLocale)),
    updateUser: (values, callback) => dispatch(updateUser(values, callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'topNavigationBar', reducer });
const withSaga = injectSaga({ key: 'topNavigationBar', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(TopNavigationBar);
