import styled from 'styled-components';
import { phoneSize } from '../../global-styles';

export default styled.div`
  display: none;
  @media (max-width: ${phoneSize}) {
    display: ${props => (props.open ? 'block' : 'none')};
    height: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    position: fixed;
    right: ${props => (props.open ? '0px' : '-260px')};
    width: 260px;
    z-index: 200;
    transition: right 0.5s ease;
    overflow-y: auto;
  }
`;
