import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const RightMessageBubble = props => (
  <div className="message from-me">
    <p className="message-body">{props.message.body}</p>
    <p className="message-time-stamp">
      {moment(props.message.timestamp).format('LT')}
    </p>
  </div>
);

RightMessageBubble.propTypes = {
  message: PropTypes.object,
};

export default RightMessageBubble;
