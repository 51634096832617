/**
 *
 * TermsAndConditions
 *
 */

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import SingleCheckBoxContent from 'components/SingleCheckBoxContent';
import Divider from 'components/Divider';
import DataLoadingSpinner from 'components/DataLoadingSpinner';

import DownArrow from './down.svg';
import makeSelectTermsAndConditions from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { fetchCurrentTermsAndConditions } from './actions';

const TermsContainer = styled.div`
  height: 180px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.1);
  border: solid 1px rgba(46, 95, 202, 0.1);
  background-color: #ffffff;
  overflow-y: hidden;
  padding: 15px 20px;
  font-size: 12px;
  color: rgba(15, 32, 69, 0.6);
  position: relative;
  margin-bottom: 20px;

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
`;

const Content = styled.div`
  overflow-y: scroll;
  height: 100%;
  text-align: left;
  scroll-behavior: smooth;
  white-space: pre-wrap;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class TermsAndConditions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { fullyScrolled: false };
  }

  // In the case that Matching/Confirmation is mounting this container and
  // tokenLoaded is undefined, fetch T&C on mount
  componentDidMount() {
    if (this.props.tokenLoaded || this.props.tokenLoaded === undefined) {
      this.props.fetchCurrentTermsAndConditions();
    }
  }

  // Check that the user's token is loaded before fetching T&C because this
  // container gets mounted quickly by UpdatedTermsAndConditions
  componentDidUpdate(prevProps) {
    if (
      this.props.tokenLoaded !== prevProps.tokenLoaded &&
      this.props.tokenLoaded
    ) {
      this.props.fetchCurrentTermsAndConditions();
    }
  }

  isFullyScrolled = () => {
    const element = document.getElementById('terms-and-conditions');
    const { fullyScrolled } = this.state;

    if (element && !fullyScrolled) {
      this.setState({
        fullyScrolled:
          element.scrollHeight - element.scrollTop - 10 <= element.clientHeight,
      });
    }
  };

  scrollDown = () => {
    const element = document.getElementById('terms-and-conditions');

    if (element) {
      const height = Number.parseInt(element.clientHeight, 10);
      const scrollAmount = height - 50;
      element.scrollBy(0, scrollAmount);
    }
  };

  renderTCsCheckboxes = () => (
    <div style={{ textAlign: 'left' }}>
      <Field
        component={SingleCheckBoxContent}
        name="read_tos"
        label={<FormattedMessage {...messages.confirmReadTos} />}
        smallText
      />
      <Field
        component={SingleCheckBoxContent}
        name="indi_counsellors"
        label={<FormattedMessage {...messages.confirmEmployee} />}
        smallText
      />
      <Field
        component={SingleCheckBoxContent}
        name="confidential"
        label={<FormattedMessage {...messages.confirmConfidential} />}
        smallText
      />
      <Divider />
    </div>
  );

  renderInformedConsentCheckboxes = () => (
    <div style={{ textAlign: 'left' }}>
      <Field
        component={SingleCheckBoxContent}
        name="by_signing"
        label={<FormattedMessage {...messages.bySigning} />}
        smallText
      />
      <Field
        component={SingleCheckBoxContent}
        name="understand_all"
        label={<FormattedMessage {...messages.understandAll} />}
        smallText
      />
      <Divider />
    </div>
  );

  render() {
    const { style, termsAndConditions, consentType } = this.props;
    const { fullyScrolled } = this.state;
    const termsText = get(termsAndConditions, 'data.text');

    return (
      <div>
        {termsAndConditions.loading ? (
          <DataLoadingSpinner />
        ) : (
          <TermsContainer style={style}>
            <Content id="terms-and-conditions" onScroll={this.isFullyScrolled}>
              {consentType === 'TCs' ? (
                termsText
              ) : (
                <FormattedMessage {...messages.consentBody} />
              )}
            </Content>
            {!fullyScrolled && (
              <div onClick={this.scrollDown} role="presentation">
                <SVG src={DownArrow} />
              </div>
            )}
          </TermsContainer>
        )}
        {consentType === 'TCs'
          ? this.renderTCsCheckboxes()
          : this.renderInformedConsentCheckboxes()}
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  fetchCurrentTermsAndConditions: PropTypes.func.isRequired,
  style: PropTypes.object,
  termsAndConditions: PropTypes.object,
  tokenLoaded: PropTypes.bool,
  consentType: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  termsAndConditions: makeSelectTermsAndConditions(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentTermsAndConditions: callback =>
      dispatch(fetchCurrentTermsAndConditions(callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'termsAndConditions', reducer });
const withSaga = injectSaga({ key: 'termsAndConditions', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(
  reduxForm({
    form: 'informedConsent',
  })(TermsAndConditions),
);
