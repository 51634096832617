import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import H2 from 'components/H2';
import H4 from 'components/H4';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { phoneSize } from '../../../global-styles';

const Container = styled.div`
  margin: 60px 0;
`;

const ResourceWrapper = styled.div`
  width: 100%;
  height: 70px;
  border: solid 1px #dfe7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
  p {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #2e5fca;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: ${phoneSize}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    height: auto;
    h4 {
      margin-bottom: 20px;
    }
  }
`;

function Resources({ provider }) {
  function renderResource(resource) {
    return (
      <ResourceWrapper key={resource.id}>
        <H4
          style={{
            fontWeight: 600,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {resource.filename}
        </H4>
        <a href={`${resource.url}`} target="_blank" rel="noopener noreferrer">
          <p>
            <FormattedMessage {...messages.download} />
          </p>
        </a>
      </ResourceWrapper>
    );
  }

  return (
    <Container>
      <H2 style={{ marginBottom: 40 }}>
        <FormattedMessage {...messages.resources} />
      </H2>
      {provider.resources.map(resource => renderResource(resource))}
    </Container>
  );
}

Resources.propTypes = {
  provider: PropTypes.object.isRequired,
};

export default Resources;
