import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import DateWithLine from './messageBubbles/DateWithLine';
import LeftMessageBubble from './messageBubbles/LeftMessageBubble';
import RightMessageBubble from './messageBubbles/RightMessageBubble';

const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  max-height: 484px;

  .message-body {
    display: inline-block;
    padding: 10px 15px;
    text-align: left;
    max-width: 60%;
    word-break: break-word;
    white-space: pre-line;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    color: #212121;
  }

  .message.from-me {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .message-body {
      background-color: #2e5fca;
      color: #ffffff;
      font-size: 16px;
      text-align: left;
      white-space: pre-line;
      font-family: 'Source Sans Pro', sans-serif;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.16px;
      font-weight: 400;
      border-radius: 20px 20px 3px 20px;
    }

    .message-time-stamp {
      text-align: right;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.16px;
      color: rgba(15, 32, 69, 0.75);
      align-self: flex-end;
      width: 4rem;
    }
  }

  .message.from-other {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;

    .message-body {
      background-color: #f3f6f6;
      font-size: 16px;
      text-align: left;
      color: #0f2045;
      white-space: pre-line;
      font-family: 'Source Sans Pro', sans-serif;
      background-color: rgba(46, 95, 202, 0.1);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.16px;
      font-weight: 400;
      border-radius: 20px 20px 20px 3px;
    }

    .message-time-stamp {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.16px;
      color: rgba(15, 32, 69, 0.75);
      width: 4rem;
      align-self: flex-end;
    }
  }

  .date-with-line {
    display: flex;
    padding-top: 30px;
    padding-bottom: 40px;

    .date {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.16px;
      font-family: 'Source Sans Pro', sans-serif;
      background-color: #ffffff;
      z-index: 100;
      display: inline;
      color: #6f798f;
      white-space: nowrap;
      margin-right: 0.1rem;
    }
    .date-line {
      width: 100%;
      margin-right: auto;
      background-color: #6f798f;
      margin-top: 0.65rem;
    }
  }
`;

class ChatContents extends React.PureComponent {
  componentDidUpdate() {
    // There is a new message in the state, scroll to bottom of list
    const objDiv = document.getElementById('messageWrapper');
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  render() {
    return (
      <MessageWrapper id="messageWrapper">
        {this.props.chatMessages &&
          this.props.chatMessages.map((singleMessage, index, originalArr) => {
            // These two logics below are for finding the first message element of each day
            // 1. check if it is a first element among messages from the first day == >index == 0
            // 2. check if it is a first element among messages from the same day  ==> (originalArr[Number(index) - 1] ~~ format( 'MMMM DD, YYYY', ))
            // - can be determined by comparing current element's - day month year - to previous element's - day month year -
            if (
              index === 0 ||
              (originalArr[Number(index) - 1] &&
                !moment(singleMessage.timestamp).isSame(
                  originalArr[Number(index) - 1].timestamp,
                  'day',
                ))
            ) {
              return (
                <div>
                  <DateWithLine message={singleMessage} />
                  {singleMessage.me === true ? (
                    // if a single message is a first message among messages from the same day &&
                    // if it is from me then print right bubble w/ dateline
                    <RightMessageBubble message={singleMessage} />
                  ) : (
                    // if a single message is a first message among messages from the same day &&
                    // if it is from me then print left bubble w/ dateline
                    <LeftMessageBubble message={singleMessage} />
                  )}
                </div>
              );
            }
            // if a single message is NOT a first message among messages from the same day
            if (singleMessage.me === true) {
              // if a single message is NOT a first message among messages from the same day &&
              // if it is from me then print right bubble wo/ dateline
              return <RightMessageBubble message={singleMessage} />;
            }
            // if a single message is NOT a first message among messages from the same day &&
            // if it is from me then print left bubble wo/ dateline
            return <LeftMessageBubble message={singleMessage} />;
          })}
      </MessageWrapper>
    );
  }
}

ChatContents.propTypes = {
  chatMessages: PropTypes.array,
};

export default ChatContents;
