/**
 *
 * UserInformation
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { setMomentTimezone } from 'utils/timezone';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { changeLocale } from 'containers/LanguageProvider/actions';

import makeSelectUser from './selectors';
import reducer from './reducer';
import saga from './saga';
import { fetchUser } from './actions';

/* eslint-disable react/prefer-stateless-function */
export class UserInformation extends React.Component {
  componentDidMount() {
    if (this.props.tokenLoaded) {
      this.fetchUserInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tokenLoaded !== this.props.tokenLoaded &&
      this.props.tokenLoaded
    ) {
      this.fetchUserInfo();
    }
  }

  fetchUserInfo = () => {
    this.props.fetchUser(user => {
      if (user && user.locale) {
        this.setLanguage(user.locale);
      }
      if (user && user.timezone) {
        setMomentTimezone(user.timezone);
      }
    });
  };

  setLanguage(userLocale) {
    const { currentLocale } = this.props;
    const newLocale = userLocale.substring(0, 2);

    if (
      (newLocale === 'en' || newLocale === 'fr') &&
      newLocale !== currentLocale
    ) {
      this.props.changeLocale(newLocale);
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

UserInformation.propTypes = {
  fetchUser: PropTypes.func,
  children: PropTypes.node,
  currentLocale: PropTypes.string,
  changeLocale: PropTypes.func,
  tokenLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  currentLocale: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchUser: callback => dispatch(fetchUser(callback)),
    changeLocale: languageLocale => dispatch(changeLocale(languageLocale)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'user', reducer });
const withSaga = injectSaga({ key: 'user', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(UserInformation);
