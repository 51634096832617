/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';
import moment from 'moment-timezone';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { compose } from 'redux';
import reducer from './reducer';
import saga from './saga';

import { makeSelectLocale } from './selectors';
import { getLanguages } from './actions';

export class LanguageProvider extends React.PureComponent {
  componentDidMount() {
    this.props.fetchLanguages();
  }

  render() {
    moment.locale(this.props.locale);
    return (
      <IntlProvider
        textComponent={React.Fragment}
        locale={this.props.locale}
        key={this.props.locale}
        messages={this.props.messages[this.props.locale]}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
  fetchLanguages: PropTypes.func,
};

const mapStateToProps = createSelector(makeSelectLocale(), locale => ({
  locale,
}));

function mapDispatchToProps(dispatch) {
  return {
    fetchLanguages: () => dispatch(getLanguages()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'language', reducer });
const withSaga = injectSaga({ key: 'language', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(LanguageProvider);
