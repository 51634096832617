import * as Sentry from '@sentry/browser';
import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';

import { API_URL } from 'utils/environment';
import { headers } from 'utils/auth';
import { get } from 'utils/request';
import { setIntercomUser } from 'utils/intercomHelpers';

import {
  FETCH_USER,
  UPDATE_USER,
  UPDATE_EMAIL,
  CONFIRM_EMAIL,
} from './constants';
import {
  updateUserSuccess,
  updateUserError,
  fetchUserSuccess,
  fetchUserError,
  updateEmailSuccess,
  updateEmailError,
  confirmEmailSuccess,
  confirmEmailError,
} from './actions';

function* fetchUser(values) {
  const requestURL = `${API_URL}/users/me`;

  try {
    const user = yield call(get, requestURL);
    yield put(fetchUserSuccess(user));

    if (values.callback) {
      yield values.callback(user);
    }

    Sentry.configureScope(scope => {
      scope.setUser({ id: user.id, email: user.email });
    });
    setIntercomUser(user.id, user.email, user.first_name, user.locale);
  } catch (error) {
    yield put(fetchUserError(error));
  }
}

const updateApi = values => {
  const requestURL = `${API_URL}/users/me`;
  return axios
    .put(requestURL, values, headers())
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

function* updateUser({ data, callback }) {
  try {
    const user = yield call(updateApi, data);
    yield put(updateUserSuccess(user));
    if (callback) {
      yield callback(user);
    }
  } catch (error) {
    yield put(updateUserError(error));
    if (callback) {
      yield callback(null, error);
    }
  }
}

const updateEmailApi = values => {
  const requestURL = `${API_URL}/emails/me`;
  return axios
    .put(requestURL, values, headers())
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

function* updateEmailDetails(values) {
  try {
    const email = yield call(updateEmailApi, values.data);
    yield put(updateEmailSuccess(email));
    yield values.callback();
  } catch (error) {
    yield put(updateEmailError(error));
  }
}

const confirmEmailApi = values => {
  const requestURL = `${API_URL}/emails/confirm_email`;
  return axios
    .put(requestURL, values, headers())
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

function* confirmEmail(values) {
  try {
    const tokenInfo = yield call(confirmEmailApi, values.data);
    yield put(confirmEmailSuccess(tokenInfo));
    yield values.callback(tokenInfo);
  } catch (error) {
    yield put(confirmEmailError(error));
  }
}

// Individual exports for testing
export default function* userInformationSaga() {
  yield takeLatest(FETCH_USER, fetchUser);
  yield takeLatest(UPDATE_EMAIL, updateEmailDetails);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(CONFIRM_EMAIL, confirmEmail);
}
