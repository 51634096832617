/*
 * Matching Messages
 *
 * This contains all the text for the Matching container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Matching';

export default defineMessages({
  phoneVerification: {
    id: `${scope}.PHONE.verification`,
    defaultMessage: 'Phone Number Verification',
  },
  phoneVerificationAuth: {
    id: `${scope}.PHONE.verificationAuth`,
    defaultMessage:
      'Your mobile phone number is needed to authenticate your account and also for security and safety purposes. ',
  },
  phoneCountryCode: {
    id: `${scope}.PHONE.countryCode`,
    defaultMessage: 'Country Code',
  },
  phoneNumber: {
    id: `${scope}.PHONE.number`,
    defaultMessage: 'Phone Number',
  },
  phoneGetPin: {
    id: `${scope}.PHONE.phoneGetPin`,
    defaultMessage: 'Get Pin',
  },
  phoneSevenDigitPin: {
    id: `${scope}.PHONE.phoneSevenDigitPin`,
    defaultMessage: 'Enter the 7-digit PIN sent to you by SMS.',
  },
  landlineSevenDigitPin: {
    id: `${scope}.PHONE.landlineSevenDigitPin`,
    defaultMessage:
      'You will receive a call at {phone}. Enter the 7-digit PIN.',
  },
  somethingWentWrong: {
    id: `${scope}.PHONE.somethingWentWrong`,
    defaultMessage: 'Something went wrong!',
  },
  phoneDidntGetCode: {
    id: `${scope}.PHONE.phoneDidntGetCode`,
    defaultMessage: "Didn't get pin? ",
  },
  phoneResend: {
    id: `${scope}.PHONE.phoneResend`,
    defaultMessage: 'Resend',
  },
  phoneDontHaveMobile: {
    id: `${scope}.PHONE.phoneDontHaveMobile`,
    defaultMessage: "I don't have a mobile phone",
  },
  verifyPhoneLater: {
    id: `${scope}.PHONE.verifyPhoneLater`,
    defaultMessage: 'I want to verify my phone number later.',
  },
  incorrectCode: {
    id: `${scope}.PHONE.incorrectCode`,
    defaultMessage: 'Incorrect code',
  },
  textedVerification: {
    id: `${scope}.PHONE.textedVerification`,
    defaultMessage: 'You have been texted a verification code!',
  },
  calledVerification: {
    id: `${scope}.PHONE.calledVerification`,
    defaultMessage: 'You will receive a call with a verification code!',
  },
  phoneNumberVerified: {
    id: `${scope}.PHONE.phoneNumberVerified`,
    defaultMessage: 'Your phone number has been verified!',
  },
  next: {
    id: `${scope}.Evaluation.next`,
    defaultMessage: 'Next',
  },
  previous: {
    id: `${scope}.Evaluation.previous`,
    defaultMessage: 'Previous',
  },
  verify: {
    id: `${scope}.PHONE.verify`,
    defaultMessage: 'Verify',
  },
  reminder: {
    id: `${scope}.PHONE.verificationAuth`,
    defaultMessage:
      'This is a reminder that a verified number is required for account authentication, security, and safety purposes.',
  },
  loading: {
    id: 'app.components.LoadingPanel.loading',
    defaultMessage: 'Loading...',
  },
});
