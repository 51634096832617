import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import H2 from 'components/H2';
import H4 from 'components/H4';
import SelectInput from 'components/SelectInput';
import Button from 'components/Button';
import XSvg from 'images/Header/X.svg';
import { timezones, intlTimezones, getTimezoneLabel } from 'utils/timezone';
import messages from './messages';
import { phoneSize, eAZoom400 } from '../../global-styles';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: rgba(15, 32, 69, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyBack = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const XIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Popup = styled.div`
  position: relative;
  width: 330px;
  min-height: 460px;
  padding: 60px 35px 30px 35px;
  border-radius: 14px;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
  background-color: #ffffff;
  z-index: 210;

  .td-timezone,
  .td-time {
    margin: 30px 0 0 0;
    h4 {
      margin: 0 0 10px 0;
    }
  }
  .td-selector {
    margin: 20px 0 0 0;
  }
  .td-button {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: ${eAZoom400}) and (min-device-width: ${phoneSize}) {
    padding: 0px 10px 10px 10px !important;    
    min-height: unset;
    height: 230px;
    overflow-y: auto;
    width: 350px;

    .td-timezone{
      margin-top: 0px;
    }

    .td-time{
      margin-top: 0px;
      display: flex;
    }

    .td-selector{
      margin-top: 0px;
    }
     
    .td-button{
      margin-top: 0px;
    }
    
    button{
      margin-top: 2px !important;
    }
    

`;

function TimezoneDialog({
  handleClose,
  handleSubmit,
  isInternational,
  timezoneObject,
  selectedTimezone,
}) {
  const [currentTimeString, setCurrentTimeString] = useState('');
  const changeTimezone = timezoneObject.get('changeTimezone');

  useEffect(
    () => {
      const interval = setInterval(() => {
        setCurrentTimeString(
          moment()
            .tz(selectedTimezone)
            .format('hh:mm A'),
        );
      }, 1000);

      return () => clearInterval(interval);
    },
    [currentTimeString, selectedTimezone],
  );

  function onSubmit(values) {
    changeTimezone(values.get('timezone'));
  }

  return ReactDOM.createPortal(
    <Wrapper>
      <EmptyBack onClick={handleClose} />
      <Popup>
        <XIcon onClick={handleClose}>
          <SVG src={XSvg} />
        </XIcon>
        <form onSubmit={handleSubmit(onSubmit)}>
          <H2>
            <FormattedMessage {...messages.selectYourTimezone} />
          </H2>
          <div className="td-timezone">
            <H4 bold>
              <FormattedMessage {...messages.selectTimezone} />:
            </H4>
            <H4>{getTimezoneLabel(selectedTimezone)}</H4>
          </div>
          <div className="td-time">
            <H4 bold>
              <FormattedMessage {...messages.timeNow} />:
            </H4>
            <H4>{currentTimeString}</H4>
          </div>
          <div className="td-selector">
            <Field
              component={SelectInput}
              options={isInternational ? intlTimezones : timezones}
              name="timezone"
            />
          </div>
          <div className="td-button">
            <Button secondary label={messages.updateTimezone} type="submit" />
          </div>
        </form>
      </Popup>
    </Wrapper>,
    document.body,
  );
}

TimezoneDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isInternational: PropTypes.bool.isRequired,
  timezoneObject: PropTypes.instanceOf(Map).isRequired,
  selectedTimezone: PropTypes.string,
};

function mapStateToProps(state) {
  const selector = formValueSelector('TimezoneDialogForm');
  const user = state.get('user');
  const gallivanCountry = user.getIn(['address', 'country']);
  const isGallivan = user.get('gallivan');

  const isInternational =
    isGallivan &&
    gallivanCountry !== 'Canada' &&
    gallivanCountry !== 'United States';

  return {
    selectedTimezone: selector(state, 'timezone'),
    isInternational,
    initialValues: {
      timezone: user.get('timezone'),
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'TimezoneDialogForm',
  })(TimezoneDialog),
);
