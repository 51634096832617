/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import LanguageButton from 'components/LanguageButton';
import { phoneSizeEnd } from 'global-styles';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import GeneralAvailabilityInfo from '../GeneralAvailabilityInfo';
import StyledHorizontalLine, { StyledVerticalLine } from '../Divider';
import Modalities from '../Modalities';
import ItemInfo from '../ItemInfo';
import IdentitiesInfo from '../IdentifyInfo';
import BulletItems from '../BulletItems';

const StyledContainer = styled.div`
  .pc-language {
    .pc-lang-buttons {
      left: -10px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .pc-item {
    margin-top: 20px;
  }
  .pc-bio {
    margin-bottom: 20px;
  }
`;

const ResponsiveRow = styled.div`
  display: flex;
  > * {
    flex: 1;
  }
  @media (max-width: ${phoneSizeEnd}) {
    flex-direction: column;
  }
  @media (min-width: ${phoneSizeEnd}) {
    > *:last-child h3 {
      margin-top: 0;
    }
  }
`;
const Flex = styled.div`
  display: flex;
`;
// eslint-disable-next-line react/prefer-stateless-function
const Profile = props => {
  const { provider } = props;
  return (
    <StyledContainer>
      <Modalities data={provider.modalities} />
      <ResponsiveRow>
        <div>
          {provider.languages.length > 0 && (
            <ItemInfo
              className="pc-language pc-item"
              key="languages"
              messageTitle="ymLanguage"
            >
              <Flex>
                {provider.languages.map(lang => (
                  <LanguageButton code={lang} key={lang} disabled />
                ))}
              </Flex>
            </ItemInfo>
          )}
          {provider.insurance_companies &&
            provider.insurance_companies.length !== 0 && (
              <ItemInfo
                className="pc-item"
                key="insurances"
                messageTitle="ymInsurance"
                content={provider.insurance_companies
                  .map(e => e.name)
                  .join(', ')}
              />
            )}
          <IdentitiesInfo className="pc-item" data={provider} />
        </div>
        <div>
          {provider.approach_to_cares.length !== 0 && (
            <BulletItems
              className="pc-item"
              messageTitle="ymApproach"
              data={provider.approach_to_cares}
            />
          )}
        </div>
      </ResponsiveRow>
      {provider.practitioner_bio && <StyledHorizontalLine />}
      <ItemInfo
        className="pc-item pc-bio"
        key="practitioner_bio"
        messageTitle="ymBio"
        content={provider.practitioner_bio}
      />
      <ResponsiveRow>
        <div>
          {provider.designations &&
            provider.designations.length !== 0 && (
              <ItemInfo
                className="pc-item"
                key="designations"
                messageTitle="ymQualification"
                content={provider.designations.join(', ')}
              />
            )}
          {provider.degrees_full &&
            provider.degrees_full.length !== 0 && (
              <ItemInfo
                className="pc-item"
                key="degrees_full"
                messageTitle="ymDegree"
                content={provider.degrees_full.join(', ')}
              />
            )}
          <BulletItems
            className="pc-item"
            data={provider.certifications}
            messageTitle="ymCertification"
          />
        </div>
        <Flex>
          {provider.general_availability && <StyledVerticalLine />}
          <GeneralAvailabilityInfo
            className="pc-item"
            data={provider.general_availability}
          />
        </Flex>
      </ResponsiveRow>
    </StyledContainer>
  );
};

Profile.propTypes = {
  provider: PropTypes.object.isRequired,
};

export default withLDConsumer()(Profile);
