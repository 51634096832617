/**
 *
 * PanelItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import H1 from 'components/H1';
import H2 from 'components/H2';
import Item from './Item';

function PanelItem({ id, divider, style, title, children, largeTitle }) {
  return (
    <Item id={id} divider={divider} style={style}>
      {title &&
        (largeTitle ? (
          <H1 style={{ margin: ' 0 0 40px 0' }}>{title}</H1>
        ) : (
          <H2 style={{ marginBottom: '40px' }}>{title}</H2>
        ))}
      {children}
    </Item>
  );
}

PanelItem.propTypes = {
  children: PropTypes.node,
  divider: PropTypes.bool,
  title: PropTypes.node,
  style: PropTypes.object,
  id: PropTypes.string,
  largeTitle: PropTypes.bool,
};

export default PanelItem;
