/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import CheckIcon from '../SqIcon/CheckIcon.svg';

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HelpTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.p`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #696e82;
  margin-bottom: 10px;
`;
const CheckBoxContainer = styled.span`
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-top: 4px;
`;

const StyledCheckBox = styled.input`
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

const StyledBox = styled.span`
  width: 16px;
  height: 16px;
  position: absolute;
  background: none;
  border-radius: 4px;
  border: ${({ error }) =>
    error ? '1.5px solid #8a4f49' : '1.5px solid #dcdee8'};

  &.hover {
    box-shadow: 0px 0px 0px 4px rgba(31, 35, 49, 0.05);
  }
  &.focus {
    box-shadow: 0px 0px 0px 4px rgba(31, 35, 49, 0.05);
  }
  &.disabled {
    border: 1.5px solid #ebecf2;
  }
  &.active {
    background: rgba(136, 153, 217, 0.12);
    border: 1.5px solid #343a54;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem;
  left: 3px;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

const StyledExtraText = styled.p`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #555a6e;

  a {
    color: #5d70ba;
  }
`;

const Asterisk = styled.span`
  color: #8a4f49;
`;

const CheckBox = props => {
  const {
    id,
    initValue,
    disabled,
    type = 'checkbox',
    name,
    onChange,
    helpText,
    title,
    extraText,
    input,
    meta,
    required = true,
  } = props;

  const [active, setActive] = useState(initValue);
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <CheckBoxWrapper>
      <Title>
        {title && title}
        {required && <Asterisk> *</Asterisk>}
      </Title>

      <HelpTextWrapper>
        <CheckBoxContainer>
          <StyledBox
            className={`${active ? 'active' : ''} ${
              disabled ? 'disabled' : ''
            } ${focus ? 'focus' : ''} ${hover ? 'hover' : ''}`}
            error={meta && meta.error}
          />
          <StyledCheckBox
            tabIndex="0"
            id={id}
            aria-describedby={`${id}-description`}
            name={name}
            type={type}
            checked={initValue}
            disabled={disabled}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={event => {
              if (onChange) onChange(event.target.checked);
              if (input) input.onChange(event.target.checked);
              setActive(event.target.checked);
            }}
            {...props}
          />
          <StyledImage src={CheckIcon} active={active} />
        </CheckBoxContainer>
        <StyledExtraText tabIndex="0" style={{ marginLeft: '10px' }}>
          {helpText && helpText}
        </StyledExtraText>
      </HelpTextWrapper>
      <text tabIndex={extraText ? 0 : -1}>{extraText && extraText}</text>
      <text style={{ color: '#8A4F49' }} tabIndex={meta.error ? 0 : -1}>
        {meta && meta.error}
      </text>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
