import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const DateWithLine = props => (
  <div className="date-with-line">
    <span className="date">{`${moment(props.message.timestamp).format(
      'dddd',
    )} ${moment(props.message.timestamp).format('MMMM DD, YYYY')}`}</span>
    <hr className="date-line" />
  </div>
);

DateWithLine.propTypes = {
  message: PropTypes.object,
};

export default DateWithLine;
