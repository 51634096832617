import { defineMessages } from 'react-intl';

export const scope = 'app.utils';

export default defineMessages({
  ht: {
    id: `${scope}.ht`,
    defaultMessage: 'Hawaii‑Aleutian Time ##',
  },
  akt: {
    id: `${scope}.akt`,
    defaultMessage: 'Alaskan Time ##',
  },
  pt: {
    id: `${scope}.pt`,
    defaultMessage: 'Pacific Time (UTC -8:00 / -7:00) ##',
  },
  mt: {
    id: `${scope}.mt`,
    defaultMessage: 'Mountain Time (UTC -7:00 / -6:00) ##',
  },
  ct: {
    id: `${scope}.ct`,
    defaultMessage: 'Central Time (UTC -6:00 / -5:00) ##',
  },
  et: {
    id: `${scope}.et`,
    defaultMessage: 'Eastern Time (UTC -5:00 / -4:00) ##',
  },
  at: {
    id: `${scope}.at`,
    defaultMessage: 'Atlantic Time (UTC -4:00 / -3:00) ##',
  },
  st: {
    id: `${scope}.st`,
    defaultMessage: 'Saskatchewan (Central Standard Time) ##',
  },
  nt: {
    id: `${scope}.nt`,
    defaultMessage: 'Newfoundland Time (UTC -2:30 / -3:30) ##',
  },
  mst: {
    id: `${scope}.mst`,
    defaultMessage: 'Yukon (Mountain Standard Time) ##',
  },
  est: {
    id: `${scope}.est`,
    defaultMessage: 'Pickle Lake/Atikokan (Eastern Standard Time) ##',
  },
});
