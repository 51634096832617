import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';

import A from 'components/A';
import H3 from 'components/H3';
import TextArea from 'components/TextArea';
import SelectInput from 'components/SelectInput';
import Spinner from 'components/DataLoadingSpinner/Spinner';
import {
  validateRequired,
  normalizePhoneNumber,
  validatePhoneNumber,
  normalizeLoanAmount,
  validateLoanAmount,
} from 'containers/Matching/helpers';
import TextInput from 'components/TextInput';
import SqAlert from 'components/SqAlert/Alert';
import messages from '../messages';
import { leaveMessageOptions } from '../data';

const SubCategoryWrapper = styled.form`
  p:first-child,
  label {
    font-size: 18px !important;
  }
  h3 {
    margin-bottom: 10px;
  }
  .margin-40 {
    margin-bottom: 40px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 60px 0 20px 0;
  align-items: center;
`;

class AdditionalInformation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount() {
    const { timezone } = this.props;
    this.props.change('timezone', timezone);
  }

  handleNext = () => {
    this.props.changeStep(2);
  };

  handlePrevious = () => {
    this.props.changeStep(0);
  };

  onSubmit = values => {
    if (!this.props.user) return;
    const { submitEmergencyReliefFund } = this.props;

    const params = {
      type_of: 'loan',
      additional_information: values.get('additionalInformation'),
      contact_value: values.get('phoneNumber'),
      leave_message_yn: values.get('leaveMessage'),
      loan_amount: values.get('loanAmount'),
    };

    if (!this.props.user.get('dob')) {
      this.setState({
        errorMessage: <FormattedMessage {...messages.missingDob} />,
        loading: false,
      });
    } else {
      this.setState({ errorMessage: null, loading: true });
      submitEmergencyReliefFund(params, () => {
        this.setState({ loading: false });
        this.handleNext();
      });
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const { loading } = this.state;

    return (
      <SubCategoryWrapper onSubmit={handleSubmit(this.onSubmit)}>
        <div style={{ marginTop: '2.5rem' }}>
          <Field
            width="500px"
            label={messages.loanAmount}
            name="loanAmount"
            component={TextInput}
            normalize={normalizeLoanAmount}
            validate={[validateRequired, validateLoanAmount]}
          />
        </div>
        <div style={{ marginTop: '1.5rem' }}>
          <Field
            width="500px"
            label={messages.contact}
            name="phoneNumber"
            component={TextInput}
            normalize={normalizePhoneNumber}
            validate={[validateRequired, validatePhoneNumber]}
          />
        </div>
        <div style={{ marginTop: '1.5rem', maxWidth: '500px' }}>
          <Field
            component={SelectInput}
            label={messages.leaveMessage}
            inputStyle={{ maxWidth: '500px' }}
            name="leaveMessage"
            options={leaveMessageOptions}
          />
        </div>
        <div style={{ marginTop: '4rem' }}>
          <H3 style={{ maxWidth: '500px', marginBottom: '-40px' }}>
            <FormattedMessage {...messages.additional} />
          </H3>
          <Field
            inputStyle={{ maxWidth: '500px' }}
            label={messages.additional}
            name="additionalInformation"
            component={TextArea}
            placeholder=""
            style={{ maxHeight: '200px' }}
          />
        </div>
        {this.state.errorMessage && (
          <SqAlert
            type="critical"
            text={this.state.errorMessage}
            style={{ width: 'fit-content' }}
          />
        )}
        <ButtonWrapper>
          {loading && (
            <>
              <Spinner />
              <p aria-live="polite" display="none">
                Loading...
              </p>
            </>
          )}
          <Button
            primary
            type="submit"
            label={messages.submit}
            style={{ margin: '0 5px' }}
            disabled={loading}
          />
        </ButtonWrapper>
        <A onClick={this.handlePrevious}>
          <FormattedMessage {...messages.previous} />
        </A>
      </SubCategoryWrapper>
    );
  }
}

AdditionalInformation.propTypes = {
  changeStep: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  submitEmergencyReliefFund: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'researchContactForm',
})(AdditionalInformation);
