import styled from 'styled-components';
import { black } from '../../global-styles';

const P = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${props => (props.color ? props.color : black)};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
`;

export default P;
