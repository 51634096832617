import { CommonLabelStyles } from 'components/CommonStyles';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropType from 'prop-types';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
`;
const StyledLabel = styled.span`
  font-family: 'TT Commons Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #696e82;
  margin-bottom: 8px;
`;
const StyledHelpText = styled.span`
  ${CommonLabelStyles} color: #696e82;
  margin-top: 8px;
`;
const StyledSelect = styled.select`
  padding: 12px;

  max-width: 374px;
  width: 100%;
  height: 40px;

  background: #ffffff;
  outline: 1px solid #dcdee8;
  border-radius: 10px;
  &:focus-visible,
  &:focus {
    outline: 2px solid #343a54 !important;
  }
  &:hover {
    outline: 1px solid #c5c8d4;
  }

  font-family: 'TT commons pro';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.02em;
  color: #2f3345;
`;
const SqDropdownButton = props => {
  const { label, helpText, style, onChange, initValue, selections } = props;
  // eslint-disable-next-line
  const [selected, setSelection] = useState(initValue);

  return (
    <Col style={style}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelect selected="" value={selected} onChange={e => onChange(e)}>
        {selections &&
          selections.map(option => (
            <option value={option.value}>{option.label}</option>
          ))}
      </StyledSelect>
      {helpText && <StyledHelpText>{helpText}</StyledHelpText>}
    </Col>
  );
};
SqDropdownButton.propTypes = {
  selections: PropType.array.isRequired,
  onChange: PropType.func,
  label: PropType.string,
  helpText: PropType.string,
  style: PropType.object,
  initValue: PropType.object,
};
export default SqDropdownButton;
