/*
 * Messaging Messages
 *
 * This contains all the text for the Messaging container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Messaging';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Messaging',
  },
  inbox: {
    id: `${scope}.inbox`,
    defaultMessage: 'Inbox',
  },
  conversations: {
    id: `${scope}.conversations`,
    defaultMessage: 'Conversations',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Send',
  },
  typeAMessage: {
    id: `${scope}.typeAMessage`,
    defaultMessage: 'Type a message...',
  },
  selectCounsellor: {
    id: `${scope}.selectCounsellor`,
    defaultMessage: 'Please select a counsellor that allows messaging',
  },
});
