/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { getTimezoneLabel } from 'utils/timezone';
import { mediumBlue, phoneSize } from '../../global-styles';
import DownSvg from './svgs/down.svg';
import TimezoneDialog from './TimezoneDialog';
import messages from './messages';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 40px 0 0;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 600;

  @media (max-width: ${phoneSize}) {
    margin: 0 30px 30px 0;
  }
  .current-timezone {
    color: #0f2045;
    text-align: right;
  }

  .time-line {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    .current-time {
      color: rgba(15, 32, 69, 0.75);
      margin: 0 9px;
    }
  }
`;

const EmptyBack = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Menu = styled.button`
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 0;
  width: 62px;
  height: 28px;
  padding: 6px 18px 7px 8px;
  box-shadow: 0 2px 20px 0 rgba(99, 140, 177, 0.4);
  background-color: #ffffff;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${mediumBlue};
`;

function TimezoneSelect({ timezoneObject }) {
  const [currentTimeString, setCurrentTimeString] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const timezone = timezoneObject.get('currentTimezone');

  useEffect(
    () => {
      const interval = setInterval(() => {
        setCurrentTimeString(moment().format('hh:mm A'));
      }, 1000);

      return () => clearInterval(interval);
    },
    [currentTimeString],
  );

  useEffect(
    () => {
      if (popupOpen) {
        document.getElementById('app').inert = true;
      } else {
        document.getElementById('app').inert = false;
      }
    },
    [popupOpen],
  );

  return (
    <Wrapper>
      <div className="current-timezone">{getTimezoneLabel(timezone)}</div>
      <button
        type="button"
        className="time-line"
        onClick={() => setMenuOpen(true)}
      >
        <div className="current-time">
          {<FormattedMessage {...messages.timeNow} />}: {currentTimeString}
        </div>
        <div className="ts-arrow">
          <img src={DownSvg} alt="Timezone dropdown" />
        </div>
      </button>
      {menuOpen && (
        <div>
          <EmptyBack onClick={() => setMenuOpen(false)} />
          <Menu
            onClick={() => {
              setMenuOpen(false);
              setPopupOpen(true);
            }}
            aria-label="Modify timezone"
          >
            <FormattedMessage {...messages.modify} />
          </Menu>
        </div>
      )}
      {popupOpen && (
        <TimezoneDialog
          timezoneObject={timezoneObject}
          handleClose={() => setPopupOpen(false)}
        />
      )}
    </Wrapper>
  );
}

TimezoneSelect.propTypes = {
  timezoneObject: PropTypes.instanceOf(Map).isRequired,
};

export default TimezoneSelect;
