/**
 *
 * AdditionalServices
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import styled from 'styled-components';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import H1 from 'components/H1';
import Content from 'components/Content';

import { tabletSize, phoneSize, mediumBlue } from 'global-styles';
import SVG from 'react-inlinesvg';
import Button from 'components/Button';
import { makeSelectUser } from 'containers/UserInformation/selectors';
import makeSelectAdditionalServices from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import Check from '../../images/Matching/check.svg';

const Panel = styled.div`
  padding: 60px;
  padding-bottom: 120px;
  border-radius: 20px;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
  background-color: #ffffff;
  .box-wrappers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    button {
      :focus {
        outline: 5px solid ${mediumBlue};
      }
    }
  }
  max-width: 940px;
  margin: 0 auto;
  @media (max-width: ${tabletSize}) {
    max-width: 688px;
  }
  @media (max-width: ${phoneSize}) {
    padding: 20px;
    .box-wrappers {
      display: block;
    }
  }
`;

const Box = styled.div`
  max-width: 300px;
  height: 500px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.2);
  border: solid 2px #1fb18c;
  background-color: #ffffff;
  padding: 40px 20px;
  text-align: center;
  margin-bottom: 60px;
  position: relative;
  margin-right: 40px;
  h2 {
    margin-bottom: 50px;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.24px;
    text-align: center;
    color: #1fb18c;
  }
  button {
    position: absolute;
    left: 90px;
    bottom: 40px;
  }
  @media (max-width: ${tabletSize}) {
    max-width: 250px;
    button {
      bottom: 20px;
      left: 60px;
    }
    margin-right: 30px;
  }
  @media (max-width: ${phoneSize}) {
    margin: 60px auto;
    max-width: 250px;
  }
`;
const CheckContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 20px;
  }
  h4,
  p {
    text-align: left;
  }

  :first-of-type {
    margin-bottom: 35px;
  }
`;
const ServiceSelection = ({ i18nTitle, i18nContentArray, handleSelect }) => (
  <Box role="tree" aria-label={i18nTitle}>
    <h2>
      <FormattedMessage {...messages[i18nTitle]} />
    </h2>
    {i18nContentArray.map(i18nContent => (
      <CheckContainer key={i18nContent}>
        <span aria-hidden="true">
          <SVG src={Check} />
        </span>
        <Content role="treeitem" aria-describedby={messages[i18nContent]}>
          <FormattedMessage {...messages[i18nContent]} />
        </Content>
      </CheckContainer>
    ))}
    <Button
      onClick={handleSelect}
      style={{ margin: 0 }}
      label={messages.select}
      secondary
    />
  </Box>
);
/* eslint-disable react/prefer-stateless-function */
export class AdditionalServices extends React.PureComponent {
  render() {
    const { intl, user, history } = this.props;
    function selectService(url) {
      history.push(`/services/${url}`);
    }
    return (
      <div>
        <Helmet>
          <title>
            {user.getIn(['company', 'name']) === 'ATB'
              ? intl.formatMessage(messages.coachingHeader)
              : intl.formatMessage(messages.header)}
          </title>
          <meta
            name="description"
            content="Description of AdditionalServices"
          />
        </Helmet>
        <Panel>
          <H1 style={{ marginBottom: '60px' }}>
            {user.getIn(['company', 'name']) === 'ATB' ? (
              <FormattedMessage {...messages.coachingHeader} />
            ) : (
              <FormattedMessage {...messages.header} />
            )}
          </H1>
          <Content style={{ marginBottom: '60px' }}>
            <FormattedMessage {...messages.atInkblot} />
            <br />
            <br />
            <FormattedMessage
              {...messages.pleaseLet}
              values={{
                email: (
                  <a
                    style={{
                      textDecoration: 'none',
                      fontWeight: '600',
                      color: '#2e5fca',
                    }}
                    href="mailto:support@inkblottherapy.com"
                  >
                    {' '}
                    <FormattedMessage {...messages.supportEmail} />
                  </a>
                ),
              }}
            />
          </Content>
          <div className="box-wrappers">
            {user.getIn(['company_additional_service', 'financial_yn']) && (
              <ServiceSelection
                i18nTitle="financial"
                i18nContentArray={['financialHotline', 'financialPlanners']}
                handleSelect={() => selectService('financial')}
              />
            )}
            {user.getIn(['company_additional_service', 'legal_yn']) && (
              <ServiceSelection
                i18nTitle="legal"
                i18nContentArray={['qualifiedAttorneys', 'barAssociation']}
                handleSelect={() => selectService('legal')}
              />
            )}
            {user.getIn(['company_additional_service', 'research_yn']) && (
              <ServiceSelection
                i18nTitle="research"
                i18nContentArray={['helpFinding', 'saveTime']}
                handleSelect={() => selectService('research')}
              />
            )}
            {user.getIn(['company_additional_service', 'health_yn']) && (
              <ServiceSelection
                i18nTitle="health"
                i18nContentArray={['infoProfessionals', 'quitSmoking']}
                handleSelect={() => selectService('health')}
              />
            )}
            {user.getIn(['company_additional_service', 'career_yn']) && (
              <ServiceSelection
                i18nTitle="career"
                i18nContentArray={['careerGoals', 'networking']}
                handleSelect={() => selectService('career')}
              />
            )}
            {user.getIn(['company_additional_service', 'loan_yn']) && (
              <Box>
                <h2>
                  <FormattedMessage {...messages.loan} />
                </h2>
                <CheckContainer>
                  <SVG src={Check} />
                  <Content>
                    <FormattedMessage
                      {...messages.emergencyFinancialAssistance}
                    />
                  </Content>
                </CheckContainer>
                <CheckContainer>
                  <SVG src={Check} />
                  <Content>
                    <FormattedMessage
                      {...messages.secureConfidentialAdjudication}
                    />
                  </Content>
                </CheckContainer>
                <Button
                  onClick={() =>
                    this.props.history.push('/services/emergencyReliefFund')
                  }
                  style={{ margin: 0 }}
                  label={messages.select}
                  secondary
                />
              </Box>
            )}
          </div>
        </Panel>
      </div>
    );
  }
}

AdditionalServices.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  additionalServices: makeSelectAdditionalServices(),
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'additionalServices', reducer });
const withSaga = injectSaga({ key: 'additionalServices', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(injectIntl(AdditionalServices));
