import { takeLatest, put, call } from 'redux-saga/effects';
import { API_URL } from '../../utils/environment';
import { get, post } from '../../utils/request';
import {
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  CONFIRM_CONSENT_INFO,
  CONFIRM_CONSENT_INFO_SUCCESS,
  CONFIRM_CONSENT_INFO_ERROR,
} from './constants';
import {
  fetchCouplesInfoSuccess,
  fetchCouplesInfoError,
  confirmConsentInfoError,
  confirmConsentInfoSuccess,
} from './actions';

function* fetchCouplesInfo({ callback }) {
  const requestURL = `${API_URL}/couples/info`;
  try {
    const data = yield call(get, requestURL);
    yield put(fetchCouplesInfoSuccess(data));
    if (callback) callback(data);
  } catch (error) {
    yield put(fetchCouplesInfoError(error));
  }
}

function* confirmConsentInfo({ params, callback }) {
  const requestURL = `${API_URL}/couples/partner_consent_confirm`;
  try {
    const data = yield call(post, requestURL, params);
    yield put(confirmConsentInfoSuccess(data));
    if (callback) callback(data);
  } catch (error) {
    yield put(confirmConsentInfoError(error));
  }
}

// Individual exports for testing
export default function* partnerSaga() {
  yield takeLatest(FETCH_COUPLES_INFO, fetchCouplesInfo);
  yield takeLatest(FETCH_COUPLES_INFO_SUCCESS, fetchCouplesInfoSuccess);
  yield takeLatest(FETCH_COUPLES_INFO_ERROR, fetchCouplesInfoError);

  yield takeLatest(CONFIRM_CONSENT_INFO, confirmConsentInfo);
  yield takeLatest(CONFIRM_CONSENT_INFO_SUCCESS, confirmConsentInfoSuccess);
  yield takeLatest(CONFIRM_CONSENT_INFO_ERROR, confirmConsentInfoError);
}
