import React from 'react';
import { FormattedMessage } from 'react-intl';

const messages = {
  invalidPostal: {
    id: `app.utils.validation.invalidPostal`,
    defaultMessage: 'Invalid Canadian Postal Code',
  },
};

export const canadianPostal = value =>
  value &&
  !/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i.test(
    value,
  ) ? (
    <FormattedMessage {...messages.invalidPostal} />
  ) : (
    undefined
  );

export const USZip = value =>
  value && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
    ? 'Invalid zip code'
    : undefined;

export const normalizeCanadianPostal = value => {
  if (!value) {
    return value;
  }

  const noSpace = value.replace(' ', '');
  const onlyUpperCase = noSpace.replace(/[a-z]/g, str => str.toUpperCase());

  if (onlyUpperCase.length <= 3) {
    return onlyUpperCase;
  }

  return `${onlyUpperCase.slice(0, 3)} ${onlyUpperCase.slice(3, 6)}`;
};

export const normalizeUSZip = value => {
  if (!value) {
    return value;
  }

  const hasLastDash = value.match(/-$/);
  const onlyDigit = value.replace(/[^\d+]/, '');

  if (onlyDigit.length <= 4) {
    return onlyDigit;
  }

  if (onlyDigit.length === 5) {
    if (hasLastDash) {
      return `${onlyDigit.slice(0, 5)}-`;
    }
    return onlyDigit;
  }

  if (onlyDigit.length === 5 && hasLastDash) {
    return `${onlyDigit.slice(0, 5)}-`;
  }

  return `${onlyDigit.slice(0, 5)}-${onlyDigit.slice(5, 9)}`;
};
