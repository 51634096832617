import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import NormalImg from 'components/Img';
import hamburgerImg from '../../images/Header/hamburger.svg';
import { phoneSize } from '../../global-styles';

const MobileButton = styled.button`
  display: none;
  @media (max-width: ${phoneSize}) {
    display: inline;
  }
`;

const HamburgerIcon = styled(NormalImg)`
  width: 25px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
`;

const Hamburger = props => (
  <MobileButton onClick={props.toggleNavSide}>
    <HamburgerIcon src={hamburgerImg} alt="Menu" />
  </MobileButton>
);

Hamburger.propTypes = {
  toggleNavSide: PropTypes.func.isRequired,
};

export default Hamburger;
