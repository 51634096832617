import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import messages from './messages';
import DashboardIcon from '../../images/Header/dashboard.svg';
import MyPractitionerIcon from '../../images/Header/my-practitioner.svg';
import SessionIcon from '../../images/Header/sessions.svg';
import PreferenceIcon from '../../images/Header/preferences.svg';
import SupportIcon from '../../images/Header/support.svg';
import AdditionalServicesIcon from '../../images/Header/additional_service.svg';

const navigationItems = [
  {
    name: 'Dashboard',
    formattedMessage: <FormattedMessage {...messages.dashboardLink} />,
    to: '/',
    icon: DashboardIcon,
  },
  {
    name: 'My Care Team',
    formattedMessage: <FormattedMessage {...messages.myPractitionerLink} />,
    to: '/my_care_provider',
    icon: MyPractitionerIcon,
  },
  {
    name: 'Appointments',
    formattedMessage: <FormattedMessage {...messages.appointmentsLink} />,
    to: '/sessions',
    icon: SessionIcon,
  },
  {
    name: 'Preferences',
    formattedMessage: <FormattedMessage {...messages.preferencesLink} />,
    to: '/preferences',
    icon: PreferenceIcon,
  },
  {
    name: 'Support',
    formattedMessage: <FormattedMessage {...messages.supportLink} />,
    to: '/support',
    icon: SupportIcon,
  },
];

const navEFAPItems = [
  {
    name: 'Dashboard',
    formattedMessage: <FormattedMessage {...messages.dashboardLink} />,
    to: '/',
    icon: DashboardIcon,
  },
  {
    name: 'My Care Provider',
    formattedMessage: <FormattedMessage {...messages.myPractitionerLink} />,
    to: '/my_care_provider',
    icon: MyPractitionerIcon,
  },
  {
    name: 'Appointments',
    formattedMessage: <FormattedMessage {...messages.appointmentsLink} />,
    to: '/sessions',
    icon: SessionIcon,
  },
  {
    name: 'Advisory Services',
    formattedMessage: <FormattedMessage {...messages.advisoryServices} />,
    to: '/services',
    icon: AdditionalServicesIcon,
  },
  {
    name: 'Preferences',
    formattedMessage: <FormattedMessage {...messages.preferencesLink} />,
    to: '/preferences',
    icon: PreferenceIcon,
  },
  {
    name: 'Support',
    formattedMessage: <FormattedMessage {...messages.supportLink} />,
    to: '/support',
    icon: SupportIcon,
  },
];

const alternativeEFAPItems = [
  {
    name: 'Dashboard',
    formattedMessage: <FormattedMessage {...messages.dashboardLink} />,
    to: '/',
    icon: DashboardIcon,
  },
  {
    name: 'My Care Provider',
    formattedMessage: <FormattedMessage {...messages.myPractitionerLink} />,
    to: '/my_care_provider',
    icon: MyPractitionerIcon,
  },
  {
    name: 'Appointments',
    formattedMessage: <FormattedMessage {...messages.appointmentsLink} />,
    to: '/sessions',
    icon: SessionIcon,
  },
  {
    name: 'Coaching Services',
    formattedMessage: <FormattedMessage {...messages.coachingServices} />,
    to: '/services',
    icon: AdditionalServicesIcon,
  },
  {
    name: 'Preferences',
    formattedMessage: <FormattedMessage {...messages.preferencesLink} />,
    to: '/preferences',
    icon: PreferenceIcon,
  },
  {
    name: 'Support',
    formattedMessage: <FormattedMessage {...messages.supportLink} />,
    to: '/support',
    icon: SupportIcon,
  },
];

export const navItems = user => {
  if (user.getIn(['company', 'name']) === 'ATB') {
    return alternativeEFAPItems;
  }
  if (
    user.get('company_additional_service') &&
    (!user.getIn(['company', 'suspended_yn']) ||
      moment(user.get('benefit_continuance_end_date')) > moment())
  ) {
    return navEFAPItems;
  }

  return navigationItems;
};
