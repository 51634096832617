import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the preferences state domain
 */

const selectPreferencesDomain = state => state.get('preferences', initialState);

/**
 * Other specific selectors
 */

const selectUserDomain = state => state.get('user', initialState);

const makeSelectDefaultCard = () =>
  createSelector(selectPreferencesDomain, substate =>
    substate.get('defaultCard'),
  );
const makeSelectCompany = () =>
  createSelector(selectPreferencesDomain, substate => substate.get('company'));

const makeSelectCouplesInfo = () =>
  createSelector(selectPreferencesDomain, substate =>
    substate.get('couplesInfo'),
  );

/**
 * Default selector used by Preferences
 */

const makeSelectPreferences = () =>
  createSelector(selectPreferencesDomain, substate => substate);

const makeSelectUser = () =>
  createSelector(selectUserDomain, substate => substate);

const makeSelectGuardians = () =>
createSelector(selectPreferencesDomain, substate => substate.get('guardians'));

export default makeSelectPreferences;
export {
  selectPreferencesDomain,
  makeSelectUser,
  makeSelectDefaultCard,
  makeSelectCompany,
  makeSelectCouplesInfo,
  makeSelectGuardians
};
