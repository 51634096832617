/**
 * A link to a certain page, an anchor tag
 */
import React from 'react';
import styled from 'styled-components';

const A = styled.a`
  color: #2e5fca;
  margin-left: 28px;
  font-weight: 600;
  cursor: pointer;
`;
const FocusableA = props => (
  <A
    {...props}
    tabIndex={0}
    onKeyDown={({ key }) => {
      if (key === 'Enter') {
        props.onClick();
      }
    }}
  />
);

export default FocusableA;
