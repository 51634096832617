/*
 *
 * Dashboard actions
 *
 */

import {
  FETCH_ONBOARDING,
  FETCH_ONBOARDING_SUCCESS,
  FETCH_ONBOARDING_ERROR,
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_SUCCESS,
  FETCH_PRACTITIONER_ERROR,
  FETCH_UPCOMING_SESSIONS,
  FETCH_UPCOMING_SESSIONS_SUCCESS,
  FETCH_UPCOMING_SESSIONS_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  FETCH_CARE_TEAM,
  FETCH_CARE_TEAM_SUCCESS,
  FETCH_CARE_TEAM_ERROR,
} from './constants';

export function fetchOnboarding() {
  return {
    type: FETCH_ONBOARDING,
  };
}

export function fetchOnboardingSuccess(onboarding) {
  return {
    type: FETCH_ONBOARDING_SUCCESS,
    onboarding,
  };
}

export function fetchOnboardingError(error) {
  return {
    type: FETCH_ONBOARDING_ERROR,
    error,
  };
}

export function fetchCompany() {
  return {
    type: FETCH_COMPANY,
  };
}

export function fetchCompanySuccess(company) {
  return {
    type: FETCH_COMPANY_SUCCESS,
    company,
  };
}

export function fetchCompanyError(error) {
  return {
    type: FETCH_COMPANY_ERROR,
    error,
  };
}

export function fetchPractitioner() {
  return {
    type: FETCH_PRACTITIONER,
  };
}

export function fetchPractitionerSuccess(practitioner) {
  return {
    type: FETCH_PRACTITIONER_SUCCESS,
    practitioner,
  };
}

export function fetchPractitionerError(error) {
  return {
    type: FETCH_PRACTITIONER_ERROR,
    error,
  };
}

export function fetchUpcomingSessions() {
  return {
    type: FETCH_UPCOMING_SESSIONS,
  };
}

export function fetchUpcomingSessionsSuccess(upcomingSessions) {
  return {
    type: FETCH_UPCOMING_SESSIONS_SUCCESS,
    upcomingSessions,
  };
}

export function fetchUpcomingSessionsError(error) {
  return {
    type: FETCH_UPCOMING_SESSIONS_ERROR,
    error,
  };
}

export function changePassword(values, callback) {
  return {
    type: CHANGE_PASSWORD,
    values,
    callback,
  };
}

export function changePasswordSuccess() {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
}

export function fetchCouplesInfo(callback) {
  return {
    type: FETCH_COUPLES_INFO,
    callback,
  };
}

export function fetchCouplesInfoSuccess(couplesInfo) {
  return {
    type: FETCH_COUPLES_INFO_SUCCESS,
    couplesInfo,
  };
}

export function fetchCouplesInfoError(error) {
  return {
    type: FETCH_COUPLES_INFO_ERROR,
    error,
  };
}

export function fetchCareTeam() {
  return {
    type: FETCH_CARE_TEAM,
  };
}

export function fetchCareTeamSuccess(careTeam) {
  return {
    type: FETCH_CARE_TEAM_SUCCESS,
    careTeam,
  };
}

export function fetchCareTeamError(error) {
  return {
    type: FETCH_CARE_TEAM_ERROR,
    error,
  };
}
