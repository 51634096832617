import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the topNavigationBar state domain
 */

const selectTopNavigationBarDomain = state =>
  state.get('topNavigationBar', initialState);

/**
 * Other specific selectors
 */
const makeSelectNewMessageInfo = () =>
  createSelector(selectTopNavigationBarDomain, substate =>
    substate.get('newMessageInfo'),
  );

/**
 * Default selector used by TopNavigationBar
 */

const makeSelectTopNavigationBar = () =>
  createSelector(selectTopNavigationBarDomain, substate => substate.toJS());

export default makeSelectTopNavigationBar;
export { selectTopNavigationBarDomain, makeSelectNewMessageInfo };
