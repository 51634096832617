/**
 *
 * Messaging
 *
 */

/* eslint no-underscore-dangle: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { Client } from '@twilio/conversations';
import _ from 'lodash';
import { compose } from 'redux';
import queryString from 'query-string';

import Breadcrumbs from 'components/Breadcrumbs';
import PanelWrapper from 'components/PanelWrapper';
import Panel from 'components/Panel';
import PanelItem from 'components/PanelItem';
import H1 from 'components/H1';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { checkNewChatMessage } from 'containers/TopNavigationBar/actions';
import {
  makeSelectChats,
  makeSelectToken,
  makeSelectChat,
  makeSelectMessages,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import Conversations from './Conversations';
import ChatWindow from './ChatWindow';
import TopUserAvatarBanner from './TopUserAvatarBanner';
import {
  fetchChats,
  fetchTwilioToken,
  fetchChat,
  fetchMessages,
  createMessage,
  markAsRead,
} from './actions';

/* eslint-disable react/prefer-stateless-function */
export class Messaging extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeChat: {},
      channel: {},
      messages: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.initChats();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChatClick = chat => {
    this.setState(
      {
        activeChat: chat,
        messages: [],
      },
      () => {
        this.props.fetchChats(() => {});
        this.retrieveMessages();
      },
    );
    this.props.fetchTwilioToken(chat.id, response => {
      const chatClient = new Client(response.id);

      chatClient.on('stateChanged', state => {
        if (state === 'initialized') {
          chatClient
            .getConversationBySid(chat.channel)
            .then(channel => {
              if (this.state.channel.removeAllListeners) {
                this.state.channel.removeAllListeners('messageAdded');
              }

              this.setState({ channel });

              channel.removeAllListeners('messageAdded');
              channel.on('messageAdded', message => {
                this.addMessage(message.state, message.conversation);
              });
              channel.on('participantLeft', () => {
                const chatId = this.state.activeChat.id;
                this.props.fetchChats(() => {
                  this.setState({ activeChat: this.props.chats[chatId] });
                });
              });
            })
            .catch(e => {
              // eslint-disable-next-line no-console
              console.log(e);
            });
        }
      });
    });
  };

  handleNewMessage = values => {
    const chatId = this.state.activeChat.id;
    this.state.channel.sendMessage(values.message);
    const noAvailabilityYn = _.get(
      this.props,
      'history.location.state.no_availability_yn',
    );

    this.props.createMessage(
      {
        id: chatId,
        message: values.message,
        sensitive_yn: true,
        no_availability_yn: noAvailabilityYn,
      },
      () => {
        this.props.fetchChat(chatId);
      },
    );
  };

  retrieveMessages = () => {
    const { activeChat } = this.state;

    this.props.fetchMessages(activeChat.id, () => {
      this.props.markAsRead(activeChat.id);
      this.props.checkNewChatMessage();
      this.formatDBMessages();
    });
  };

  // Initial fetch messages from our db
  formatDBMessages = () => {
    const chatMessages = this.props.messages;
    const totalMessages = Object.keys(chatMessages).length;
    const messageArray = [];
    for (let chatMessage = 0; chatMessage < totalMessages; chatMessage += 1) {
      const messageData = {
        body: chatMessages[chatMessage].message,
        me: chatMessages[chatMessage].from_me,
        timestamp: chatMessages[chatMessage].created_at,
      };
      messageArray.push(messageData);
    }
    this.setState(prevState => ({
      messages: [...prevState.messages, ...messageArray],
    }));
  };

  initChats = () => {
    this.props.fetchChats(() => {
      const { chats, location } = this.props;
      const { search } = location;
      if (!_.isEmpty(chats)) {
        // eslint-disable-next-line prefer-destructuring
        const chatId = queryString.parse(search).chatId;

        let activeChat;
        const allowedChat = _.find(chats, chat => chat.allow_chat_yn === true);
        if (chatId) {
          activeChat = chats[chatId];
        } else if (allowedChat) {
          activeChat = allowedChat;
        } else {
          activeChat = _.find(chats, chat => chat);
        }

        if (!_.isEmpty(activeChat)) {
          this.onChatClick(activeChat);
        }
      }
    });
  };

  addMessage = (message, channel) => {
    const { activeChat } = this.state;
    // eslint-disable-next-line no-underscore-dangle
    if (channel.sid === activeChat.channel && this._isMounted) {
      const chatMessages = this.state.messages;
      const newMessage = {
        ...message,
        me: message.author === this.props.token.identity,
      };
      this.setState({
        messages: [...chatMessages, newMessage],
      });

      if (!newMessage.me) this.props.markAsRead(activeChat.id);
    }
  };

  render() {
    const { chats } = this.props;
    const { activeChat } = this.state;
    const chatMessages = this.state.messages;
    return (
      <div>
        <Helmet>
          <title>Messaging</title>
          <meta name="description" content="Messaging with counsellors" />
        </Helmet>
        <Breadcrumbs names={[<FormattedMessage {...messages.header} />]} />
        <PanelWrapper>
          <Panel>
            <H1>
              <FormattedMessage {...messages.inbox} />
            </H1>
            <TopUserAvatarBanner activeChat={activeChat} />
            <PanelItem style={{ paddingTop: '2rem' }}>
              <ChatWindow
                handleNewMessage={this.handleNewMessage}
                chatMessages={chatMessages}
                allowChatYn={
                  _.isEmpty(activeChat) ? false : activeChat.allow_chat_yn
                }
                chat={activeChat}
              />
            </PanelItem>
          </Panel>
          <Panel>
            <Conversations
              chats={chats}
              onChatClick={this.onChatClick}
              activeChat={activeChat}
            />
          </Panel>
        </PanelWrapper>
      </div>
    );
  }
}

Messaging.propTypes = {};

const mapStateToProps = createStructuredSelector({
  chats: makeSelectChats(),
  token: makeSelectToken(),
  chat: makeSelectChat(),
  messages: makeSelectMessages(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchChats: callback => dispatch(fetchChats(callback)),
    fetchTwilioToken: (chatId, callback) =>
      dispatch(fetchTwilioToken(chatId, callback)),
    fetchChat: id => dispatch(fetchChat(id)),
    fetchMessages: (id, callback) => dispatch(fetchMessages(id, callback)),
    createMessage: (id, message, sensitiveYn, callback) =>
      dispatch(createMessage(id, message, sensitiveYn, callback)),
    markAsRead: (id, callback) => dispatch(markAsRead(id, callback)),
    checkNewChatMessage: () => dispatch(checkNewChatMessage()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'messaging', reducer });
const withSaga = injectSaga({ key: 'messaging', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Messaging);
