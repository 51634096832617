import styled from 'styled-components';
import { black } from '../../global-styles';

const H4 = styled.h4`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: ${props => (props.bold ? 600 : 400)};
  color: ${props => (props.color ? props.color : black)};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: ${props => (props.bold ? '.2px' : 'normal')};
`;

export default H4;
