import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { setToken, setBackTo } from 'utils/auth';

class ExternalTokenRedirect extends React.PureComponent {
  componentDidMount() {
    const { history, match } = this.props;

    if (match.params.token) {
      const qs = queryString.parse(window.location.search);

      const state = qs.log_amp ? { logAmplitude: true } : undefined;

      if (qs.back_to) {
        setBackTo(qs.back_to);
      }

      setToken(match.params.token);
      if (qs.partner_registration) {
        // redirect to Partner registration for a new account registration from Marketing app
        history.push('/partner_registration', state);
      } else {
        history.push('/', state);
      }
      window.location.reload();
    }
  }

  render() {
    return <p>Redirecting....</p>;
  }
}

ExternalTokenRedirect.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(ExternalTokenRedirect);
