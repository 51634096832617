import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import H2 from 'components/H2';
import Content from 'components/Content';
import { connect } from 'react-redux';
import messages from '../messages';
import DropzoneInput from './DropzoneInput';
import { uploadFile } from '../actions';
import { mediumBlue, phoneSize } from '../../../global-styles';

const Container = styled.div`
  margin: 60px 0;
  .fs-wrapper {
    display: flex;
    align-items: center;
    margin-top: 40px;
    button {
      :focus {
        outline: 5px solid ${mediumBlue};
      }
    }
    .fs-dropzone {
      width: 160px;
    }
    .fs-filename {
      height: 40px;
      min-width: 150px;
      margin: 0 40px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    @media (max-width: ${phoneSize}) {
      flex-direction: column;
      align-items: flex-start;
      .fs-filename {
        margin: 20px 0;
      }
    }
  }
`;

class SendFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      fileName: '',
    };
  }

  onFileChange = file => {
    const body = new FormData();
    body.append('file', file[0]);
    this.setState({
      file: body,
      fileName: file[0].name,
    });
  };

  sendFiles = () => {
    const { provider } = this.props;
    if (this.state.file) {
      this.props.uploadFile(provider.id, this.state.file);
      this.setState({
        file: '',
        fileName: <FormattedMessage {...messages.fileSent} />,
      });
    } else {
      this.setState({
        fileName: <FormattedMessage {...messages.pleaseSelectFile} />,
      });
    }
  };

  render() {
    return (
      <Container>
        <H2 style={{ marginBottom: 20 }}>
          <FormattedMessage {...messages.sendFiles} />
        </H2>
        <Content>
          <FormattedMessage {...messages.useThisTool} />
        </Content>
        <div className="fs-wrapper">
          <div className="fs-dropzone">
            <DropzoneInput
              onChange={this.onFileChange}
              accept=".jpg, .jpeg, .gif, .png, .pdf, .doc"
              Component={
                <Button
                  tertiary
                  label={messages.selectFile}
                  style={{ margin: 0 }}
                />
              }
            />
          </div>
          <div className="fs-filename">
            <Content bold>{this.state.fileName}</Content>
          </div>
          <div className="fs-send-button">
            <Button
              singleClick={false}
              secondary
              label={messages.send}
              onClick={this.sendFiles}
              style={{ margin: 0 }}
            />
          </div>
        </div>
      </Container>
    );
  }
}
SendFiles.propTypes = {
  provider: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    uploadFile: (providerId, file) => dispatch(uploadFile(providerId, file)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(SendFiles);
