import { takeLatest, put as reduxPut, call } from 'redux-saga/effects';
import { API_URL } from '../../utils/environment';
import {
  fetchChatsSuccess,
  fetchChatsError,
  fetchTwilioTokenSuccess,
  fetchTwilioTokenError,
  fetchChatSuccess,
  fetchChatError,
  fetchMessagesSuccess,
  fetchMessagesError,
  createMessageSuccess,
  createMessageError,
  markAsReadSuccess,
  markAsReadError,
} from './actions';
import {
  FETCH_CHATS,
  FETCH_TWILIO_TOKEN,
  FETCH_CHAT,
  FETCH_MESSAGES,
  CREATE_MESSAGE,
  MARK_AS_READ,
} from './constants';
import { get, post, put } from '../../utils/request';

function* fetchTwilioToken(values) {
  const requestURL = `${API_URL}/chats/token?chat_id=${values.chatId}`;
  try {
    const token = yield call(get, requestURL);
    yield reduxPut(fetchTwilioTokenSuccess(token));
    if (values.callback) yield values.callback(token);
  } catch (error) {
    yield reduxPut(fetchTwilioTokenError(error));
  }
}

function* fetchChats(values) {
  const requestURL = `${API_URL}/chats?query=${
    values.query ? values.query : ''
  }`;
  try {
    const chats = yield call(get, requestURL);
    yield reduxPut(fetchChatsSuccess(chats));
    if (values.callback) yield call(values.callback);
  } catch (error) {
    yield reduxPut(fetchChatsError(error));
  }
}

function* fetchChat(values) {
  const requestURL = `${API_URL}/chats?id=${values.id}`;
  try {
    const chat = yield call(get, requestURL);
    yield reduxPut(fetchChatSuccess(chat));
    if (values.callback) yield call(values.callback);
  } catch (error) {
    yield reduxPut(fetchChatError(error));
  }
}

function* fetchMessages(values) {
  const requestURL = `${API_URL}/chats/messages?id=${values.id}`;
  try {
    const chat = yield call(get, requestURL);
    yield reduxPut(fetchMessagesSuccess(chat));
    if (values.callback) yield call(values.callback);
  } catch (error) {
    yield reduxPut(fetchMessagesError(error));
  }
}

function* createMessage(values) {
  const params = values.chatId;
  const requestURL = `${API_URL}/chats/message`;

  try {
    const message = yield call(post, requestURL, params);
    yield reduxPut(createMessageSuccess(message));
    if (values.callback) yield call(values.callback);
  } catch (error) {
    yield reduxPut(createMessageError(error));
  }
}

function* markAsRead(values) {
  const params = { id: values.chatId };
  const requestURL = `${API_URL}/chats/mark_as_read`;
  try {
    yield call(put, requestURL, params);
    yield reduxPut(markAsReadSuccess());
    if (values.callback) yield call(values.callback);
  } catch (error) {
    yield reduxPut(markAsReadError(error));
  }
}

// Individual exports for testing
export default function* messagingSaga() {
  yield takeLatest(FETCH_CHATS, fetchChats);
  yield takeLatest(FETCH_TWILIO_TOKEN, fetchTwilioToken);
  yield takeLatest(FETCH_CHAT, fetchChat);
  yield takeLatest(FETCH_MESSAGES, fetchMessages);
  yield takeLatest(CREATE_MESSAGE, createMessage);
  yield takeLatest(MARK_AS_READ, markAsRead);
}
