/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import FontFaceObserver from 'fontfaceobserver';
import TagManager from 'react-gtm-module';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';

// Import css for rsuite ver from 5.6.6
import 'rsuite/dist/rsuite.min.css';

import './fonts/index.css';

import * as Sentry from '@sentry/browser';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

// import environment
import { SENTRY_DSN, ENVIRONMENT } from 'utils/environment';

// Import amplitude
import { initAmplitude } from 'utils/amplitude';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Hook Sentry up
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: ENV_APP_VERSION, // eslint-disable-line no-undef
  });
}
// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const sourceSansProObserver = new FontFaceObserver('Source Sans Pro', {});

// When Source Sans Pro is loaded, add a font-family Source Sans Pro to the body
sourceSansProObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

// GTM
TagManager.initialize({
  gtmId: 'GTM-PPKWFTX',
  auth: '6SgJRYDz4oNSskHmW-a87A',
  preview: 'env-1',
});

// Amplitude
initAmplitude();

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }
