import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'containers/Matching/messages';

export const validateRequired = value =>
  value ? undefined : <FormattedMessage {...messages.pleaseComplete} />;

export const validatePhoneNumber = value =>
  value && !/^\d{3}-\d{3}-\d{4}$/i.test(value) ? (
    <div aria-live="polite" role="alert">
      <FormattedMessage {...messages.invalidPhone} />
    </div>
  ) : (
    undefined
  );

export const normalizePhoneNumber = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

export const validateEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value) ? (
    <div aria-live="polite" role="alert">
      <FormattedMessage {...messages.validEmail} />
    </div>
  ) : (
    undefined
  );

export const validateEmailMatch = (value, allValues) =>
  value !== allValues.get('email_new') ? (
    <div aria-live="polite" role="alert">
      <FormattedMessage {...messages.emailMatch} />
    </div>
  ) : (
    undefined
  );

export const validateBirthday = dateString => {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
    return (
      <div aria-live="polite" role="alert">
        <FormattedMessage {...messages.validDate} />
      </div>
    );

  // Parse the date parts to integers
  const parts = dateString.split('/');
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check range of year
  if (year < 1880 || year > new Date().getFullYear()) {
    return <FormattedMessage {...messages.yearRange} />;
  }

  // Check the ranges of month
  if (month === 0 || month > 12) {
    return <FormattedMessage {...messages.monthRange} />;
  }

  // Check the range of the day
  if (!(day > 0 && day <= monthLength[month - 1])) {
    return <FormattedMessage {...messages.dayRange} />;
  }
  // if (calculateAge(dateString) < 15) {
  //   return <FormattedMessage {...messages.minimumAge} />;
  // }
  return false;
};

export const calculateAge = dob => {
  const day = dob.substring(0, 2);
  const month = dob.substring(3, 5);
  const year = dob.substring(6, 10);
  const adjustedDOB = `${month}/${day}/${year}`;

  const today = new Date();
  const birthDate = new Date(adjustedDOB);
  let age = today.getFullYear() - birthDate.getFullYear();
  const diff = today.getMonth() - birthDate.getMonth();

  if (diff < 0 || (diff === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const normalizeDate = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8,
  )}`;
};

export const validatePasswordLength = value =>
  value && value.length < 8 ? (
    <div aria-live="polite" role="alert">
      <FormattedMessage {...messages.passwordLength} />
    </div>
  ) : (
    ''
  );

export const validatePasswordsMatch = (value, allValues) =>
  value !== allValues.get('password') ? (
    <div aria-live="polite" role="alert">
      <FormattedMessage {...messages.passwordMatch} />
    </div>
  ) : (
    undefined
  );
