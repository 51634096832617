let rootUrl;
let apiUrl;
let apiUrlNoVersion;
let serverUrl;
let practiceUrl;
let marketingUrl;
let wellnessHubUrl;
let stripeKey;
let ENV;
let sentryDsn;
let country;
let currency;
let amplitudeApiKey;
let assessUrl;
let ldClientId;
let otherTherapyUrl;
let otherServerUrl;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
  ENV = 'DEVELOPMENT';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'http://localhost:10101';
  otherTherapyUrl = 'http://localhost:10101';
  apiUrl = 'http://localhost:3000/api/v2';
  apiUrlNoVersion = 'http://localhost:3000/api';
  serverUrl = 'http://localhost:3000';
  otherServerUrl = 'http://localhost:3000';
  practiceUrl = 'http://localhost:8080';
  marketingUrl = 'http://localhost:8000';
  wellnessHubUrl = 'http://localhost:8000';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  assessUrl = 'http://localhost:7070';
  ldClientId = '61aa7abf805c8710cfad8b2e';
} else if (
  hostname === 'test.inkblottherapy.com' ||
  hostname === 'staging.inkblottherapy.com'
) {
  ENV = 'STAGING';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://staging.inkblottherapy.com';
  otherTherapyUrl = 'https://us-staging.inkblottherapy.com';
  apiUrl = 'https://api.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://api.inkblottherapy.com/api';
  serverUrl = 'https://api.inkblottherapy.com';
  otherServerUrl = 'https://api.inkblottherapy.com';
  practiceUrl = 'https://staging.inkblotpractice.com';
  marketingUrl = 'https://staging-marketing.inkblottherapy.com';
  wellnessHubUrl = 'https://wellnesshub.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
} else if (hostname === 'app.inkblottherapy.com') {
  ENV = 'PRODUCTION';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://app.inkblottherapy.com';
  otherTherapyUrl = 'https://portal.inkblottherapy.com';
  apiUrl = 'https://api.inkblotpractice.com/api/v2';
  apiUrlNoVersion = 'https://api.inkblotpractice.com/api';
  serverUrl = 'https://api.inkblotpractice.com';
  otherServerUrl = 'https://medapi.inkblottherapy.com';
  practiceUrl = 'https://app.inkblotpractice.com';
  marketingUrl = 'https://inkblottherapy.com';
  wellnessHubUrl = 'https://wellnesshub.inkblottherapy.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  sentryDsn = 'https://4b46f2acb43147169e3b887718b2d002@sentry.io/1461274';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  assessUrl = 'https://inkblotassess.com';
  ldClientId = '61a4f5fda8e6530f447dbcf9';
} else if (hostname === 'uat.inkblottherapy.com') {
  ENV = 'UAT';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://uat.inkblottherapy.com';
  otherTherapyUrl = 'https://uat.inkblottherapy.com';
  apiUrl = 'https://uatapi.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://uatapi.inkblottherapy.com/api';
  serverUrl = 'https://uatapi.inkblottherapy.com';
  otherServerUrl = 'https://uatapi.inkblottherapy.com';
  practiceUrl = 'https://uat.inkblotpractice.com';
  marketingUrl = 'https://uat-marketing.inkblottherapy.com';
  wellnessHubUrl = 'https://wellness-hub.netlify.app';
  stripeKey = '';
  sentryDsn = null;
  amplitudeApiKey = null;
  ldClientId = '61ba324c1c39840e58ab0ffd';
} else if (hostname === 'demo.inkblottherapy.com') {
  ENV = 'DEMO';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://demo.inkblottherapy.com';
  otherTherapyUrl = 'https://demo.inkblottherapy.com';
  apiUrl = 'https://demoapi.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://demoapi.inkblottherapy.com/api';
  serverUrl = 'https://demoapi.inkblottherapy.com';
  otherServerUrl = 'https://demoapi.inkblottherapy.com';
  practiceUrl = 'https://demo.inkblotpractice.com';
  marketingUrl = 'https://demo-marketing.inkblottherapy.com';
  wellnessHubUrl = 'https://wellness-hub.netlify.app';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  sentryDsn = null;
  amplitudeApiKey = null;
  ldClientId = '622b6838855e3b14fa2358b2';
} else if (hostname === 'us-staging.inkblottherapy.com') {
  ENV = 'US DEV';
  country = 'US';
  currency = 'USD';
  rootUrl = 'https://us-staging.inkblottherapy.com';
  otherTherapyUrl = 'https://staging.inkblottherapy.com';
  apiUrl = 'https://usapi.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://usapi.inkblottherapy.com/api';
  serverUrl = 'https://usapi.inkblottherapy.com';
  otherServerUrl = 'https://usapi.inkblottherapy.com';
  practiceUrl = 'https://us-staging.inkblotpractice.com';
  marketingUrl = 'https://us-staging-marketing.inkblottherapy.com';
  wellnessHubUrl = 'https://wellness-hub.netlify.app';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  sentryDsn = null;
  amplitudeApiKey = null;
  ldClientId = '61ba3223f940930e6232e840';
} else if (hostname === 'portal.inkblottherapy.com') {
  ENV = 'US PROD';
  country = 'US';
  currency = 'USD';
  rootUrl = 'https://portal.inkblottherapy.com';
  otherTherapyUrl = 'https://app.inkblottherapy.com';
  apiUrl = 'https://medapi.inkblottherapy.com/api/v2';
  apiUrlNoVersion = 'https://medapi.inkblottherapy.com/api';
  serverUrl = 'https://medapi.inkblottherapy.com';
  otherServerUrl = 'https://api.inkblotpractice.com';
  practiceUrl = 'https://portal.inkblotpractice.com';
  marketingUrl = 'https://us.inkblottherapy.com';
  wellnessHubUrl = 'https://wellness-hub.netlify.app';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  assessUrl = 'https://us.inkblotassess.com';
  sentryDsn = 'https://4b46f2acb43147169e3b887718b2d002@sentry.io/1461274';
  amplitudeApiKey = '19a913a504c377ea2dc305213318fbb6';
  ldClientId = '61a4f5fda8e6530f447dbcf9';
} else if (hostname === 'qa1-app.inkblottherapy.com') {
  ENV = 'QA1';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://qa1-app.inkblottherapy.com';
  otherTherapyUrl = 'https://qa1-app.inkblottherapy.com';
  apiUrl = 'https://qa1-api.inkblottherapy.com/api/v2';
  serverUrl = 'https://qa1-api.inkblottherapy.com';
  otherServerUrl = 'https://qa1-api.inkblottherapy.com';
  practiceUrl = 'https://qa1-app.inkblotpractice.com';
  marketingUrl = 'https://qa1.inkblottherapy.com';
  wellnessHubUrl = 'https://qa1-wellnesshub.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
} else if (hostname === 'qa2-app.inkblottherapy.com') {
  ENV = 'QA2';
  country = 'CA';
  currency = 'CAD';
  rootUrl = 'https://qa2-app.inkblottherapy.com';
  otherTherapyUrl = 'https://qa2-app.inkblottherapy.com';
  apiUrl = 'https://qa2-api.inkblottherapy.com/api/v2';
  serverUrl = 'https://qa2-api.inkblottherapy.com';
  otherServerUrl = 'https://qa2-api.inkblottherapy.com';
  practiceUrl = 'https://qa2-app.inkblotpractice.com';
  marketingUrl = 'https://qa2.inkblottherapy.com';
  wellnessHubUrl = 'https://qa2-wellnesshub.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  sentryDsn = null;
  amplitudeApiKey = '6c0ab20165f5afa194f84b2b1080d6f6';
  ldClientId = '61ba3223f940930e6232e840';
}

export const ROOT_URL = `${rootUrl}`;
export const API_URL = `${apiUrl}`;
export const API_URL_NO_VERSION = `${apiUrlNoVersion}`;
export const SERVER_URL = `${serverUrl}`;
export const OTHER_SERVER_URL = `${otherServerUrl}`;
export const PRACTICE_URL = `${practiceUrl}`;
export const MARKETING_URL = `${marketingUrl}`;
export const WELLNESS_HUB_URL = `${wellnessHubUrl}`;
export const STRIPE_KEY = `${stripeKey}`;
export const ENVIRONMENT = `${ENV}`;
export const SENTRY_DSN = sentryDsn;
export const COUNTRY = `${country}`;
export const CURRENCY = `${currency}`;
export const AMPLTIUDE_API_KEY = amplitudeApiKey;
export const ASSESS_URL = `${assessUrl}`;
export const LD_CLIENT_ID = `${ldClientId}`;
export const OTHER_THERAPY_URL = `${otherTherapyUrl}`;
