/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import MailIcon from './MailIcon';
import DownSvg from './svgs/down.svg';
import { mediumBlue, phoneSize } from '../../global-styles';
import DetectClickOutsideWrapper from './DetectClickOutsideWrapper';
import NavDropdown from './NavDropdown';
import TimezoneSelect from './TimezoneSelect';

const StyledName = styled.button`
  cursor: pointer;
  display: flex;

  .usn-name {
    span {
      font-family: 'Source Sans Pro', sans-serif;
      color: ${mediumBlue};
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
    }
  }
  .usn-arrow {
    margin: -1px 0 0 8px;
  }
`;
const StyledLink = styled(Link)`
  margin-right: 45px;
  &:hover, &:focus {
    color: ${mediumBlue};
    outline: 5px auto ${mediumBlue};
    font-weight: bold;
    letter-spacing: -0.001527em;
    svg {
      stroke: ${mediumBlue};
      path {
        stroke: ${mediumBlue};
      }
    }
}`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${phoneSize}) {
    display: none;
  }
`;

const UserInfo = ({
  userName,
  Avatar,
  toggleDropDown,
  open,
  newMessageInfo,
  handleSignOut,
  localeObject,
  timezoneObject,
}) => (
  <Wrapper>
    <TimezoneSelect timezoneObject={timezoneObject} />
    <StyledLink to="/messaging">
      <MailIcon newMessageInfo={newMessageInfo} />
    </StyledLink>
    {Avatar}
    <DetectClickOutsideWrapper toggle={toggleDropDown} open={open}>
      <div>
        <StyledName onClick={toggleDropDown}>
          <div className="usn-name">
            <span>{userName}</span>
          </div>
          <div className="usn-arrow">
            <img src={DownSvg} alt="User menu dropdown" />
          </div>
        </StyledName>
        <NavDropdown
          open={open}
          toggleDropDown={toggleDropDown}
          handleSignOut={handleSignOut}
          localeObject={localeObject}
        />
      </div>
    </DetectClickOutsideWrapper>
  </Wrapper>
);

UserInfo.propTypes = {
  userName: PropTypes.string.isRequired,
  Avatar: PropTypes.node,
  toggleDropDown: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  newMessageInfo: PropTypes.instanceOf(Map),
  handleSignOut: PropTypes.func.isRequired,
  localeObject: PropTypes.instanceOf(Map).isRequired,
  timezoneObject: PropTypes.instanceOf(Map).isRequired,
};

export default UserInfo;
