/**
 *
 * Button
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { InkblotTheme, Button as InkButton } from 'inkblot-ui';
import { FormattedMessage } from 'react-intl';

import { logEvent } from 'utils/amplitude';
import styled from 'styled-components';

const StyledInkButton = styled(InkButton)`
  &:enabled {
    &:hover,
    &:focus {
      box-shadow: 0 0 10px 0 rgba(99, 140, 177, 0.6);
    }
  }
`;
function Button({
  onClick,
  label,
  style,
  eventType,
  eventProperties,
  ...props
}) {
  const handleClick = () => {
    if (eventType) logEvent(eventType, eventProperties);
    onClick();
  };

  return (
    <InkblotTheme>
      <StyledInkButton
        {...props}
        style={{ marginTop: '40px', ...style }}
        label={<FormattedMessage {...label} />}
        onClick={handleClick}
        singleClick={false}
      />
    </InkblotTheme>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  eventType: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  eventType: '',
};

export default Button;
