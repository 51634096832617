/*
 *
 * Preferences actions
 *
 */

import {
  FETCH_CONTACT_DETAILS,
  FETCH_CONTACT_DETAILS_SUCCESS,
  FETCH_CONTACT_DETAILS_ERROR,
  UPDATE_CONTACT_DETAILS_ERROR,
  UPDATE_CONTACT_DETAILS_SUCCESS,
  UPDATE_CONTACT_DETAILS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  CREATE_DEPENDANT,
  CREATE_DEPENDANT_SUCCESS,
  CREATE_DEPENDANT_ERROR,
  FETCH_DEFAULT_CARD,
  FETCH_DEFAULT_CARD_SUCCESS,
  FETCH_DEFAULT_CARD_ERROR,
  UPDATE_DEFAULT_CARD,
  UPDATE_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD_ERROR,
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  CREATE_GUARDIANS,
  CREATE_GUARDIANS_SUCCESS,
  CREATE_GUARDIANS_ERROR,
  FETCH_GUARDIANS,
  FETCH_GUARDIANS_SUCCESS,
  FETCH_GUARDIANS_ERROR,
} from './constants';

export function fetchContactDetails() {
  return {
    type: FETCH_CONTACT_DETAILS,
  };
}

export function fetchContactDetailsSuccess(contactDetails) {
  return {
    type: FETCH_CONTACT_DETAILS_SUCCESS,
    contactDetails,
  };
}

export function fetchContactDetailsError(error) {
  return {
    type: FETCH_CONTACT_DETAILS_ERROR,
    error,
  };
}

export function updateContactDetails(data, callback) {
  return {
    type: UPDATE_CONTACT_DETAILS,
    data,
    callback,
  };
}

export function updateContactDetailsSuccess(contactDetails) {
  return {
    type: UPDATE_CONTACT_DETAILS_SUCCESS,
    contactDetails,
  };
}

export function updateContactDetailsError(error) {
  return {
    type: UPDATE_CONTACT_DETAILS_ERROR,
    error,
  };
}

export function updatePassword(data, callback) {
  return {
    type: UPDATE_PASSWORD,
    data,
    callback,
  };
}

export function updatePasswordSuccess(password) {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    password,
  };
}

export function updatePasswordError(error) {
  return {
    type: UPDATE_PASSWORD_ERROR,
    error,
  };
}

export function createDependant(data) {
  return {
    type: CREATE_DEPENDANT,
    data,
  };
}

export function createDependantSuccess(dependant) {
  return {
    type: CREATE_DEPENDANT_SUCCESS,
    dependant,
  };
}

export function createDependantError(error) {
  return {
    type: CREATE_DEPENDANT_ERROR,
    error,
  };
}

export function fetchDefaultCard() {
  return {
    type: FETCH_DEFAULT_CARD,
  };
}

export function fetchDefaultCardSuccess(card) {
  return {
    type: FETCH_DEFAULT_CARD_SUCCESS,
    card,
  };
}

export function fetchDefaultCardError(error) {
  return {
    type: FETCH_DEFAULT_CARD_ERROR,
    error,
  };
}

export function updateDefaultCard(token) {
  return {
    type: UPDATE_DEFAULT_CARD,
    token,
  };
}

export function updateDefaultCardSuccess(card) {
  return {
    type: UPDATE_DEFAULT_CARD_SUCCESS,
    card,
  };
}

export function updateDefaultCardError(error) {
  return {
    type: UPDATE_DEFAULT_CARD_ERROR,
    error,
  };
}

export function fetchCompany() {
  return {
    type: FETCH_COMPANY,
  };
}

export function fetchcompanySuccess(company) {
  return {
    type: FETCH_COMPANY_SUCCESS,
    company,
  };
}

export function fetchcompanyError(error) {
  return {
    type: FETCH_COMPANY_ERROR,
    error,
  };
}

export function fetchCouplesInfo() {
  return {
    type: FETCH_COUPLES_INFO,
  };
}

export function fetchCouplesInfoSuccess(couplesInfo) {
  return {
    type: FETCH_COUPLES_INFO_SUCCESS,
    couplesInfo,
  };
}

export function fetchCouplesInfoError(error) {
  return {
    type: FETCH_COUPLES_INFO_ERROR,
    error,
  };
}

export function createGuardians(values, callback) {
  return {
    type: CREATE_GUARDIANS,
    values,
    callback,
  };
}

export function createGuardiansSuccess(guardians) {
  return {
    type: CREATE_GUARDIANS_SUCCESS,
    guardians,
  };
}

export function createGuardiansError(error) {
  return {
    type: CREATE_GUARDIANS_ERROR,
    error,
  };
}

export function fetchGuardians(userId, callback) {
  return {
    type: FETCH_GUARDIANS,
    userId,
    callback,
  };
}

export function fetchGuardiansSuccess(guardians) {
  return {
    type: FETCH_GUARDIANS_SUCCESS,
    guardians,
  };
}

export function fetchGuardiansError(error) {
  return {
    type: FETCH_GUARDIANS_ERROR,
    error,
  };
}
