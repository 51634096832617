import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the misc state domain
 */

const selectMiscDomain = state => state.get('misc', initialState);

/**
 * Other specific selectors
 */

const makeSelectCanadianProvinces = () =>
  createSelector(selectMiscDomain, substate => substate.get('canada'));
/**
 * Default selector used by Misc
 */

const makeSelectMisc = () =>
  createSelector(selectMiscDomain, substate => substate.toJS());

export default makeSelectMisc;
export { selectMiscDomain, makeSelectCanadianProvinces };
