/*
 *
 * Preferences constants
 *
 */

export const FETCH_CONTACT_DETAILS = 'app/Preferences/FETCH_CONTACT_DETAILS';
export const FETCH_CONTACT_DETAILS_SUCCESS =
  'app/Preferences/FETCH_CONTACT_DETAILS_SUCCESS';
export const FETCH_CONTACT_DETAILS_ERROR =
  'app/Preferences/FETCH_CONTACT_DETAILS_ERROR';

export const UPDATE_CONTACT_DETAILS = 'app/Preferences/UPDATE_CONTACT_DETAILS';
export const UPDATE_CONTACT_DETAILS_SUCCESS =
  'app/Preferences/UPDATE_CONTACT_DETAILS_SUCCESS';
export const UPDATE_CONTACT_DETAILS_ERROR =
  'app/Preferences/UPDATE_CONTACT_DETAILS_ERROR';

export const UPDATE_PASSWORD = 'app/Preferences/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS =
  'app/Preferences/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'app/Preferences/UPDATE_PASSWORD_ERROR';

export const CREATE_DEPENDANT = 'app/Preferences/CREATE_DEPENDANT';
export const CREATE_DEPENDANT_SUCCESS =
  'app/Preferences/CREATE_DEPENDANT_SUCCESS';
export const CREATE_DEPENDANT_ERROR = 'app/Preferences/CREATE_DEPENDANT_ERROR';

export const FETCH_DEFAULT_CARD = 'app/Preferences/FETCH_DEFAULT_CARD';
export const FETCH_DEFAULT_CARD_SUCCESS =
  'app/Preferences/FETCH_DEFAULT_CARD_SUCCESS';
export const FETCH_DEFAULT_CARD_ERROR =
  'app/Preferences/FETCH_DEFAULT_CARD_ERROR';

export const UPDATE_DEFAULT_CARD = 'app/Preferences/UPDATE_DEFAULT_CARD';
export const UPDATE_DEFAULT_CARD_SUCCESS =
  'app/Preferences/UPDATE_DEFAULT_CARD_SUCCESS';
export const UPDATE_DEFAULT_CARD_ERROR =
  'app/Preferences/UPDATE_DEFAULT_CARD_ERROR';

export const FETCH_COMPANY = 'app/Preferences/FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'app/Preferences/FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'app/Preferences/FETCH_COMPANY_ERROR';

export const FETCH_COUPLES_INFO = 'app/Dashboard/FETCH_COUPLES_INFO';
export const FETCH_COUPLES_INFO_SUCCESS =
  'app/Dashboard/FETCH_COUPLES_INFO_SUCCESS';
export const FETCH_COUPLES_INFO_ERROR =
  'app/Dashboard/FETCH_COUPLES_INFO_ERROR';
export const CREATE_GUARDIANS = 'app/Preferences/CREATE_GUARDIANS';
export const CREATE_GUARDIANS_SUCCESS = 'app/Preferences/CREATE_GUARDIANS_SUCCESS';
export const CREATE_GUARDIANS_ERROR = 'app/Preferences/CREATE_GUARDIANS_ERROR';
export const FETCH_GUARDIANS = 'app/Preferences/FETCH_GUARDIANS';
export const FETCH_GUARDIANS_SUCCESS = 'app/Preferences/FETCH_GUARDIANS_SUCCESS';
export const FETCH_GUARDIANS_ERROR = 'app/Preferences/FETCH_GUARDIANS_ERROR';
