import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the userInformation state domain
 */

const selectUserInformationDomain = state => state.get('user', initialState);

/**
 * Other specific selectors
 */

const makeSelectFromAtb = () =>
  createSelector(
    selectUserInformationDomain,
    substate => substate.getIn(['company', 'name']) === 'ATB',
  );

/**
 * Default selector used by UserInformation
 */

const makeSelectUserInformation = () =>
  createSelector(selectUserInformationDomain, substate => substate.toJS());

const makeSelectUser = () =>
  createSelector(selectUserInformationDomain, substate => substate);

export default makeSelectUserInformation;
export { selectUserInformationDomain, makeSelectUser, makeSelectFromAtb };
