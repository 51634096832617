import { deletePhoneVerificationReminder } from 'containers/PhoneVerificationDialog';
import { ROOT_URL } from './environment';

export function setToken(token) {
  return localStorage.setItem('token', token);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function headers(token = null) {
  return {
    headers: {
      Authorization: token || getToken(),
    },
  };
}

export function isUnauthorized(error) {
  if (error && error.response && error.response.status === 401) {
    return true;
  }

  return false;
}

export function signOut() {
  // clean local storage
  localStorage.removeItem('token');
  localStorage.removeItem('last_action_at');
  deletePhoneVerificationReminder();

  // redirect to marketing site
  window.location = `${ROOT_URL}/signin`;
}

export function unauthSignOut() {
  const exceptionalRoutes = ['/redirect', '/confirmation', '/two_factor'];
  const currentPath = window.location.pathname;
  const isExceptional = exceptionalRoutes.some(route =>
    currentPath.includes(route),
  );

  if (!isExceptional) {
    signOut();
  }
}

export function setBackTo(href) {
  return localStorage.setItem('back_to', href);
}

export function getBackTo() {
  return localStorage.getItem('back_to');
}
