import { CommonLabelStyles } from 'components/CommonStyles';
import React, { useState } from 'react';
import { SQTOKEN } from 'squid-styles';
import styled from 'styled-components';

const StyledRadioCard = styled.button`
  padding: 16px;

  width: 363px;
  height: 74px;
  background: ${({ active }) =>
    active ? SQTOKEN['alias-color-bg-accent'] : 'white'};
  border: ${({ active }) =>
    active
      ? `1.5px solid ${SQTOKEN['alias-color-border-active']} !important`
      : '1px solid #dcdee8'};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus {
    border: 1.5px solid ${SQTOKEN['alias-color-border-hover']} !important;
    outline: none;
  }
`;
const StyledLabel = styled.span`
  font-weight: 600;
  ${CommonLabelStyles}
  color: ${SQTOKEN['alias-color-text-weeker']};
  &.Active {
    color: ${SQTOKEN['alias-color-text-on-accent']};
  }
`;
const StyledSubLabel = styled.span`
  ${CommonLabelStyles}
  color: ${SQTOKEN['alias-color-text-weekest']};
  &.Active {
    color: ${SQTOKEN['alias-color-text-weeker']};
  }
  margin-top: 2px;
`;

const RadioCard = props => {
  const [active, setActive] = useState(props.initValue || false);
  return (
    <StyledRadioCard
      aria-label={`${active ? 'Checked' : 'Uncheck'} ${props.label}`}
      tabIndex={0}
      active={active}
      {...props}
      onClick={() => {
        setActive(pre => !pre);
        if (props.onChange) props.onChange(active);
        if (props.onClick) props.onClick(active);
      }}
      onKeyUp={event => {
        event.preventDefault();
        if (event.key === 'Enter') setActive(pre => !pre);
      }}
    >
      {props.label && (
        <StyledLabel className={active ? 'Active' : ''}>
          {props.label}
        </StyledLabel>
      )}
      {props.subLabel && (
        <StyledSubLabel className={active ? 'Active' : ''}>
          {props.subLabel}
        </StyledSubLabel>
      )}
    </StyledRadioCard>
  );
};

export default RadioCard;
