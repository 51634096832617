import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import PhoneNumberVerification from 'containers/PhoneNumberVerification';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeSelectUser } from 'containers/UserInformation/selectors';
import { PHONE_VERIFICATION_DIALOG } from 'components/Dialog/constants';
import { updateTrigger } from 'components/Dialog/actions';
import makeSelectDialogTrigger from 'components/Dialog/selectors';

const ReminderKey = '1timePhoneVerificationReminded';
export const checkPhoneVerificationRemindered = () =>
  localStorage.getItem(ReminderKey) &&
  JSON.parse(localStorage.getItem(ReminderKey)) === true;
export const setPhoneVerificationReminded = () =>
  localStorage.setItem(ReminderKey, true);
export const deletePhoneVerificationReminder = () =>
  localStorage.removeItem(ReminderKey);

function PhoneVerificationDialog({ user, dialogTriggers, popupDialog }) {
  const User = user && user.toJS();
  const [open, setOpen] = useState(false);
  useEffect(
    () => {
      const trigger =
        dialogTriggers && dialogTriggers[PHONE_VERIFICATION_DIALOG];
      if (
        !User.loaded ||
        (User.primary_phone_number &&
          User.primary_phone_number.verified_yn === true) ||
        window.location.pathname.includes('/partner_registration')
      )
        return;
      // manual trigger from CTA button
      if (trigger) {
        setOpen(true);
        return;
      }
      // auto-trigger on init
      // if (!checkPhoneVerificationRemindered()) {
      //   // timeout for waiting last Reminder showup
      //   setTimeout(() => {
      //     setPhoneVerificationReminded();
      //     setOpen(true);
      //   }, 300);
      // }
    },
    [User, dialogTriggers],
  );

  return (
    <Dialog bodyOnly maxWidth="698px" open={open}>
      {open && (
        <PhoneNumberVerification
          isReminder
          openExitDialog={() => setOpen(false)}
          goToNextPage={() => {
            setOpen(false);
            popupDialog(PHONE_VERIFICATION_DIALOG, false);
          }}
        />
      )}
    </Dialog>
  );
}

PhoneVerificationDialog.propTypes = {
  user: PropTypes.object.isRequired,
  dialogTriggers: PropTypes.object,
  popupDialog: PropTypes.func,
};
const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  dialogTriggers: makeSelectDialogTrigger(),
});
const mapDispatchToProps = dispatch => ({
  popupDialog: (dialogName, value) =>
    dispatch(updateTrigger({ dialogName, value })),
});
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
export default compose(withConnect)(PhoneVerificationDialog);
