/*
 * UpdatedTermsConditions Messages
 *
 * This contains all the text for the UpdatedTermsConditions container.
 */

import React from 'react';
import { defineMessages } from 'react-intl';

import { black } from 'global-styles';

export const scope = 'app.containers.UpdatedTermsConditions';

export default defineMessages({
  updatedTC: {
    id: `${scope}.updatedTC`,
    defaultMessage: 'Updated Terms & Conditions',
  },
  lastRevised: {
    id: `${scope}.lastRevised`,
    defaultMessage: 'Last Revised:',
  },
  accept: {
    id: `${scope}.accept`,
    defaultMessage: 'Accept',
  },
  decline: {
    id: `${scope}.decline`,
    defaultMessage: 'Decline',
  },
  pleaseRead: {
    id: `${scope}.pleaseRead`,
    defaultMessage:
      'Please read the following terms of agreement. We have adjusted our {policy} as of April 1, 2022.',
  },
  pleaseReadBenevity: {
    id: `${scope}.pleaseReadBenevity`,
    defaultMessage:
      'Please read the following terms of agreement. We have adjusted our {policy} for Benevity users as of April 1, 2022.',
  },
  contactSupport: {
    id: `${scope}.contactSupport`,
    defaultMessage:
      'If you have any questions, please contact {email} or click on the green icon at the bottom right of your screen to chat with a live agent.',
    values: {
      email: (
        <a href="mailto:support@inkblottherapy.com" style={{ color: black }}>
          support@inkblottherapy.com
        </a>
      ),
    },
  },
  mustAccept: {
    id: `${scope}.mustAccept`,
    defaultMessage:
      'You must accept the revised Terms & Conditions in order to continue using Inkblot.',
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: 'Go back',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Log out',
  },
  tncCheckBoxErrorMessages: {
    id: `app.containers.Matching.Error.tncCheckBoxErrorMessages`,
    defaultMessage: 'Please indicate your agreement by checking the box. ',
  },
});
