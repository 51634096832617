import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import H2 from 'components/H2';
import H3 from 'components/H3';
import Button from 'components/Button';
import messages from '../messages';
import { tabletSize, phoneSize } from '../../../global-styles';

const StyledContainer = styled.div`
  .pc-availability {
    min-height: 40px;
    padding: 10px 20px;
    margin: 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .pc-header {
    display: flex;
    .pc-avatar {
      width: 220px;
      height: 100%;
      background-color: #ecf2f4;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      img {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        border-top-left-radius: 20px;
      }
    }
    .pc-general {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .pc-title-button {
        display: flex;
        justify-content: space-between;
        .pc-title {
          margin: 20px 0 20px 20px;
        }
        .pc-upper-button {
          margin: 20px 40px 0 0;
        }
      }
      .pc-name {
        margin: 0 0 0 20px;
      }
    }
  }

  .pc-availability-upper {
    display: flex;
  }
  .pc-availability-lower {
    display: none;
  }
  .pc-header-mobile {
    display: none;
  }
  .pc-lower-button {
    display: none;
  }

  @media (max-width: ${tabletSize}) {
    .pc-availability-upper {
      display: none;
    }
    .pc-availability-lower {
      display: flex;
    }
    .pc-header {
      .pc-avatar {
        width: 160px;
        height: 160px;
        border-bottom-left-radius: 0;
      }
    }
  }

  @media (max-width: ${phoneSize}) {
    .pc-header {
      display: none;
    }
    .pc-header-mobile {
      display: block;
      .pc-title {
        margin: 20px;
      }
      .pc-avatar-name {
        display: flex;
        margin: 0 20px;
        .pc-avatar {
          width: 80px;
          height: 80px;
          background-color: #ecf2f4;
          border-radius: 50%;
        }
        .pc-name {
          margin-left: 20px;
          display: flex;
          align-items: center;
        }
      }
    }
    .pc-lower-button {
      display: flex;
      justify-content: center;
      margin: 20px 0 40px 0;
    }
  }
`;

function NoProvider({ title, matchUrl, history }) {
  const matchingButton = () => (
    <Button
      primary
      label={messages.findMyCareProvider}
      style={{
        margin: '0',
        width: '220px',
        padding: '0 25px',
      }}
      onClick={() => history.push(matchUrl)}
    />
  );

  return (
    <StyledContainer>
      {/* Web, Table Header */}
      <div className="pc-header">
        <div className="pc-avatar" />
        <div className="pc-general">
          <div className="pc-title-button">
            <div className="pc-title">
              <H2>{title}</H2>
            </div>
            <div className="pc-upper-button">{matchingButton()}</div>
          </div>
          <div className="pc-name">
            <H3>
              <FormattedMessage {...messages.noPractitioner} />
            </H3>
          </div>
          <div className="pc-availability pc-availability-upper">
            <FormattedMessage {...messages.haventMatched} />
          </div>
        </div>
      </div>
      {/* Mobile Header */}
      <div className="pc-header-mobile">
        <div className="pc-title">
          <H2>{title}</H2>
        </div>
        <div className="pc-avatar-name">
          <div className="pc-avatar" />
          <div className="pc-name">
            <H3>
              <FormattedMessage {...messages.noPractitioner} />
            </H3>
          </div>
        </div>
      </div>
      <div className="pc-availability pc-availability-lower">
        <FormattedMessage {...messages.haventMatched} />
      </div>
      <div className="pc-lower-button">{matchingButton()}</div>
    </StyledContainer>
  );
}

NoProvider.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  matchUrl: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(NoProvider);
