import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import PanelWrapper from 'components/PanelWrapper';
import Panel from 'components/Panel';
import PanelItem from 'components/PanelItem';
import H1 from 'components/H1';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { makeSelectUser } from 'containers/UserInformation/selectors';

import messages from './messages';
import Stepper from './Stepper';
import Introduction from './Introduction';
import AdditionalInformation from './AdditionalInformation';
import Confirm from './Confirm';
import reducer from './reducer';
import saga from './saga';
import { mediumBlue } from '../../global-styles';
import { submitEmergencyReliefFund } from './actions';

const StyledPanel = styled(Panel)`
  :first-of-type {
    width: 100%;
    border-radius: 20px;
    background-color: #ffffff;
  }
  button {
    :focus {
      outline: 5px solid ${mediumBlue};
    }
  }
  a {
    :focus {
      outline: 5px solid ${mediumBlue};
    }
  }
`;

/* eslint-disable react/prefer-stateless-function */
export class EmergencyReliefFund extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      currentStep: 0, // 0: Introduction, 1: Category, 2: , 3: Contact, 4: Confirm
    };
  }

  changeStep = newStep => {
    this.setState({ currentStep: newStep });
    window.scrollTo(0, 0);
  };

  renderCurrentStep() {
    const { currentStep } = this.state;

    switch (currentStep) {
      case 0:
        return <Introduction changeStep={this.changeStep} />;
      case 1:
        return (
          <AdditionalInformation
            changeStep={this.changeStep}
            submitEmergencyReliefFund={this.props.submitEmergencyReliefFund}
            user={this.props.user}
          />
        );
      case 2:
        return <Confirm changeStep={this.changeStep} />;
      default:
    }

    return <noscript />;
  }

  render() {
    const { currentStep } = this.state;
    const { intl, user, history } = this.props;
    const loanYn = user.getIn(['company_additional_service', 'loan_yn']);
    if (user.get('loaded') && !loanYn) {
      history.push('/services');
    }
    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.header)}</title>
          <meta name="description" content="Emergency Relief Fund" />
        </Helmet>
        <PanelWrapper>
          <StyledPanel>
            <H1>
              <FormattedMessage {...messages.header} />
            </H1>
            <PanelItem style={{ position: 'relative', zIndex: '1' }}>
              <div>
                <Stepper currentStep={currentStep} onChange={this.changeStep} />
                {this.renderCurrentStep()}
              </div>
            </PanelItem>
          </StyledPanel>
        </PanelWrapper>
      </div>
    );
  }
}

EmergencyReliefFund.propTypes = {
  history: PropTypes.object.isRequired,
  intl: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    submitEmergencyReliefFund: (values, callback) =>
      dispatch(submitEmergencyReliefFund(values, callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'emergencyReliefFund', reducer });
const withSaga = injectSaga({ key: 'emergencyReliefFund', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(injectIntl(EmergencyReliefFund));
