/*
 * Financial Services Messages
 *
 * This contains all the text for the FinancialServices container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.EmergencyReliefFund';

export default defineMessages({
  introduction: {
    id: `${scope}.introduction`,
    defaultMessage: 'Introduction',
  },
  additionalInformation: {
    id: `${scope}.additionalInformation`,
    defaultMessage: 'Additional Information',
  },
  confirmation: {
    id: `${scope}.confirmation`,
    defaultMessage: 'Confirmation',
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Employee Emergency Relief Fund',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  theEmployeeReliefFundServices: {
    id: `${scope}.theEmployeeReliefFundServices`,
    defaultMessage:
      'The Employee Relief Fund services may assist you with dealing with temporary or immediate financial challenges during difficult times of transition or trauma, so that you may focus on the health of you and your family.',
  },
  someExamples0fSituationsWhere: {
    id: `${scope}.someExamples0fSituationsWhere`,
    defaultMessage:
      'Some examples of situations where an Emergency Relief Fund may be used are: Funerary services for a deceased family member, emergency house or car repairs, an event that makes it impossible to pay for basic living expenses (e.g., flood, fire, robbery, domestic violence), temporary medical expenses not otherwise covered by benefits.',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Phone number to be contacted',
  },
  leaveMessage: {
    id: `${scope}.leaveMessage`,
    defaultMessage: 'Would you like to be left a voicemail message?',
  },
  additional: {
    id: `${scope}.additional`,
    defaultMessage:
      'Please describe additional information that would help choose the best advisor for your needs.',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  loanAmount: {
    id: `${scope}.loanAmount`,
    defaultMessage: 'What is the amount being requested?',
  },
  confirmationCopy: {
    id: `${scope}.confirmationCopy`,
    defaultMessage:
      'Your request has been received! Your loan advisor will be in touch shortly to confirm eligibility, outline documentation requirements and ask some additional questions. You should receive a call within 1 business day.',
  },
  returnToDash: {
    id: `${scope}.returnToDash`,
    defaultMessage: 'Return to Dashboard',
  },
  missingDob: {
    id: `${scope}.missingDob`,
    defaultMessage: 'Please enter a date of birth on your Preferences page.',
  },
});
