/**
 *
 * UpdatePopover
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { InkblotTheme, Button } from 'inkblot-ui';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import H3 from 'components/H3';
import makeSelectUserSettings from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { fetchUserSettings, updateUserSettings } from './actions';

const PopoverWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 30px;
  min-height: 125px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);
  min-width: 250px;
  z-index: 900;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export class UpdatePopover extends React.Component {
  componentDidMount() {
    if (this.props.tokenLoaded) {
      this.props.fetchUserSettings();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tokenLoaded !== this.props.tokenLoaded &&
      this.props.tokenLoaded
    ) {
      this.props.fetchUserSettings();
    }
  }

  /* eslint-disable no-restricted-globals */
  updateSettings = () => {
    this.props.updateUserSettings({ update_available_yn: false }, () => {
      location.reload(true);
    });
  };

  render() {
    const { settings } = this.props;
    if (settings.get('update_available_yn')) {
      return (
        <PopoverWrapper>
          <H3 style={{ marginBottom: 20 }}>
            <FormattedMessage {...messages.updateAvailable} />
          </H3>
          <InkblotTheme>
            <Button
              label={<FormattedMessage {...messages.refresh} />}
              tertiary
              onClick={() => this.updateSettings()}
            />
          </InkblotTheme>
        </PopoverWrapper>
      );
    }
    return <noscript />;
  }
}

UpdatePopover.propTypes = {
  fetchUserSettings: PropTypes.func,
  tokenLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  settings: makeSelectUserSettings(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchUserSettings: callback => dispatch(fetchUserSettings(callback)),
    updateUserSettings: (data, callback) =>
      dispatch(updateUserSettings(data, callback)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'user_updates', reducer });
const withSaga = injectSaga({ key: 'user_updates', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(UpdatePopover);
