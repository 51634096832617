/*
 * AdditionalServices Messages
 *
 * This contains all the text for the AdditionalServices container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AdditionalServices';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Advisory Services',
  },
  coachingHeader: {
    id: `${scope}.coachingHeader`,
    defaultMessage: 'Coaching Services',
  },
  ourServices: {
    id: `${scope}.ourServices`,
    defaultMessage: 'Coaching & Advisory Services',
  },
  atInkblot: {
    id: `${scope}.atInkblot`,
    defaultMessage:
      'At Inkblot, we make life less stressful by providing you with additional consulting services.',
  },
  weSought: {
    id: `${scope}.weSought`,
    defaultMessage:
      'We have sought the highest-quality options available and streamlined the process.',
  },
  pleaseLet: {
    id: `${scope}.pleaseLet`,
    defaultMessage:
      'Please let us know at {email} if there are particular services that would be of interest to you.',
  },
  financial: {
    id: `${scope}.financial`,
    defaultMessage: 'Financial',
  },
  financialHotline: {
    id: `${scope}.financialHotline`,
    defaultMessage: 'Full-service financial hotline',
  },
  financialPlanners: {
    id: `${scope}.financialPlanners`,
    defaultMessage: 'Staffed by professional Financial Planners',
  },
  legal: {
    id: `${scope}.legal`,
    defaultMessage: 'Legal',
  },
  qualifiedAttorneys: {
    id: `${scope}.qualifiedAttorneys`,
    defaultMessage: 'Highly qualified attorneys',
  },
  barAssociation: {
    id: `${scope}.barAssociation`,
    defaultMessage: 'All of our attorneys are members of the Bar Association',
  },
  research: {
    id: `${scope}.research`,
    defaultMessage: 'Research Assistance',
  },
  helpFinding: {
    id: `${scope}.helpFinding`,
    defaultMessage:
      'Help with finding local resources such as eldercare, childcare, etc.',
  },
  saveTime: {
    id: `${scope}.saveTime`,
    defaultMessage: 'Save time as we do the research for you',
  },
  health: {
    id: `${scope}.health`,
    defaultMessage: 'Health Advisory',
  },
  infoProfessionals: {
    id: `${scope}.infoProfessionals`,
    defaultMessage: 'Information from registered professionals',
  },
  quitSmoking: {
    id: `${scope}.quitSmoking`,
    defaultMessage: 'Quit smoking, nutrition advice, navigate healthcare, etc.',
  },
  career: {
    id: `${scope}.career`,
    defaultMessage: 'Career Counselling',
  },
  careerGoals: {
    id: `${scope}.careerGoals`,
    defaultMessage: 'Discuss your career goals and path',
  },
  networking: {
    id: `${scope}.networking`,
    defaultMessage: 'Learn networking and practical skills',
  },
  loan: {
    id: `${scope}.loan`,
    defaultMessage: 'Employee Emergency Relief Fund',
  },
  emergencyFinancialAssistance: {
    id: `${scope}.emergencyFinancialAssistance`,
    defaultMessage: 'Emergency financial assistance in the form of a loan',
  },
  secureConfidentialAdjudication: {
    id: `${scope}.secureConfidentialAdjudication`,
    defaultMessage: 'Secure/Confidential adjudication process',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select',
  },
  supportEmail: {
    id: `${scope}.supportEmail`,
    defaultMessage: 'support@inkblottherapy.com',
  },
});
