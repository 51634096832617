/*
 *
 * MyCareProvider actions
 *
 */

import {
  DESTROY_MATCH,
  DESTROY_MATCH_ERROR,
  DESTROY_MATCH_SUCCESS,
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_ERROR,
  FETCH_PRACTITIONER_SUCCESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE,
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  FETCH_CARE_TEAM,
  FETCH_CARE_TEAM_SUCCESS,
  FETCH_CARE_TEAM_ERROR,
} from './constants';

export function fetchPractitioner() {
  return {
    type: FETCH_PRACTITIONER,
  };
}

export function fetchPractitionerSuccess(practitioner) {
  return {
    type: FETCH_PRACTITIONER_SUCCESS,
    practitioner,
  };
}

export function fetchPractitionerError(error) {
  return {
    type: FETCH_PRACTITIONER_ERROR,
    error,
  };
}

export function destroyMatch(id, feedback, callback) {
  return {
    type: DESTROY_MATCH,
    id,
    feedback,
    callback,
  };
}

export function destroyMatchSuccess(match) {
  return {
    type: DESTROY_MATCH_SUCCESS,
    match,
  };
}

export function destroyMatchError(error) {
  return {
    type: DESTROY_MATCH_ERROR,
    error,
  };
}

export function uploadFile(providerId, file) {
  return {
    type: UPLOAD_FILE,
    providerId,
    file,
  };
}

export function uploadFileSuccess(file) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    file,
  };
}

export function uploadFileError(error) {
  return {
    type: UPLOAD_FILE_ERROR,
    error,
  };
}

export function fetchCouplesInfo() {
  return {
    type: FETCH_COUPLES_INFO,
  };
}

export function fetchCouplesInfoSuccess(couplesInfo) {
  return {
    type: FETCH_COUPLES_INFO_SUCCESS,
    couplesInfo,
  };
}

export function fetchCouplesInfoError(error) {
  return {
    type: FETCH_COUPLES_INFO_ERROR,
    error,
  };
}

export function fetchCareTeam() {
  return {
    type: FETCH_CARE_TEAM,
  };
}

export function fetchCareTeamSuccess(careTeam) {
  return {
    type: FETCH_CARE_TEAM_SUCCESS,
    careTeam,
  };
}

export function fetchCareTeamError(error) {
  return {
    type: FETCH_CARE_TEAM_ERROR,
    error,
  };
}
