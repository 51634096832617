/**
 *
 * EmailConfirmation
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { confirmEmail } from 'containers/UserInformation/actions';
import { connect } from 'react-redux';

/* eslint-disable react/prefer-stateless-function */
export class EmailConfirmation extends Component {
  componentDidMount() {
    const { match, history } = this.props;
    const confirmationToken = match.params.confirmation_token;

    this.props.confirmEmail(
      { confirmation_token: confirmationToken },
      tokenInfo => {
        history.push(`/redirect/${tokenInfo.token}`);
      },
    );
  }

  render() {
    return <p>Confirming account....</p>;
  }
}

EmailConfirmation.propTypes = {
  confirmEmail: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    confirmEmail: (values, callback) =>
      dispatch(confirmEmail(values, callback)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(EmailConfirmation);
