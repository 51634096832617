import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';

import { headers } from 'utils/auth';
import { API_URL } from 'utils/environment';
import { get, post } from 'utils/request';

import {
  FETCH_CONTACT_DETAILS,
  UPDATE_CONTACT_DETAILS,
  UPDATE_PASSWORD,
  CREATE_DEPENDANT,
  FETCH_DEFAULT_CARD,
  UPDATE_DEFAULT_CARD,
  FETCH_COMPANY,
  FETCH_COUPLES_INFO,
  FETCH_GUARDIANS,
  CREATE_GUARDIANS,
} from './constants';
import {
  fetchContactDetailsSuccess,
  fetchContactDetailsError,
  updateContactDetailsSuccess,
  updateContactDetailsError,
  updatePasswordSuccess,
  updatePasswordError,
  createDependantSuccess,
  createDependantError,
  fetchDefaultCardSuccess,
  fetchDefaultCardError,
  updateDefaultCardSuccess,
  updateDefaultCardError,
  fetchcompanyError,
  fetchcompanySuccess,
  fetchCouplesInfoSuccess,
  fetchCouplesInfoError,
  createGuardiansSuccess,
  createGuardiansError,
  fetchGuardiansSuccess,
  fetchGuardiansError,
} from './actions';

const getApi = () => {
  const requestURL = `${API_URL}/contact_details/me`;
  return axios
    .get(requestURL, headers())
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

function* fetchContactDetails() {
  try {
    const user = yield call(getApi);
    yield put(fetchContactDetailsSuccess(user));
  } catch (error) {
    yield put(fetchContactDetailsError(error));
  }
}

const updateApi = values => {
  const requestURL = `${API_URL}/contact_details/me`;
  return axios
    .put(requestURL, values, headers())
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

function* updateContactDetails(values) {
  try {
    const user = yield call(updateApi, values.data);
    yield put(updateContactDetailsSuccess(user));
    yield values.callback();
  } catch (error) {
    yield put(updateContactDetailsError(error));
  }
}

const updatePasswordApi = values => {
  const requestURL = `${API_URL}/passwords/me`;
  return axios
    .put(requestURL, values, headers())
    .then(response => response.data)
    .catch(error => {
      throw error.response;
    });
};

function* updatePassword(values) {
  try {
    const password = yield call(updatePasswordApi, values.data);
    yield put(updatePasswordSuccess(password));
    yield values.callback();
  } catch (error) {
    yield put(updatePasswordError(error));
  }
}

function* fetchCompany() {
  const requestURL = `${API_URL}/company`;
  try {
    const company = yield call(get, requestURL);
    yield put(fetchcompanySuccess(company));
  } catch (error) {
    yield put(fetchcompanyError(error));
  }
}

function* createDependant(values) {
  const requestURL = `${API_URL}/dependants`;
  try {
    const dependant = yield call(post, requestURL, values.data);
    yield put(createDependantSuccess(dependant));
  } catch (error) {
    yield put(createDependantError(error));
  }
}

function* fetchDefaultCard() {
  const requestURL = `${API_URL}/stripe_customers`;

  try {
    const card = yield call(get, requestURL);
    yield put(fetchDefaultCardSuccess(card));
  } catch (error) {
    yield put(fetchDefaultCardError(error));
  }
}

function* updateDefaultCard({ token }) {
  const requestURL = `${API_URL}/stripe_customers`;

  try {
    const card = yield call(post, requestURL, token);
    yield put(updateDefaultCardSuccess(card));
  } catch (error) {
    yield put(updateDefaultCardError(error));
  }
}

function* fetchCouplesInfo() {
  const requestURL = `${API_URL}/couples/info`;

  try {
    const couplesInfo = yield call(get, requestURL);
    yield put(fetchCouplesInfoSuccess(couplesInfo));
  } catch (error) {
    yield put(fetchCouplesInfoError(error));
  }
}

function* createGuardians({ values, callback }) {
  const requestURL = `${API_URL}/guardians`;

  try {
    const guardians = yield call(post, requestURL, values);
    yield put(createGuardiansSuccess(guardians));
    if (callback) yield call(callback, true, null);
  } catch (error) {
    yield put(createGuardiansError(error));
    if (callback) yield call(callback, false, error);
  }
}

function* fetchGuardians({ userId , callback}) {
  const requestURL = `${API_URL}/guardians/${userId}`;

  try {
    const guardians = yield call(get, requestURL);
    yield put(fetchGuardiansSuccess(guardians));
    if (callback) yield call(callback, true, null);
  } catch (error) {
    yield put(fetchGuardiansError(error));
    if (callback) yield call(callback, false, error);
  }
}


// Individual exports for testing
export default function* userInformationSaga() {
  yield takeLatest(CREATE_DEPENDANT, createDependant);
  yield takeLatest(FETCH_CONTACT_DETAILS, fetchContactDetails);
  yield takeLatest(FETCH_COMPANY, fetchCompany);
  yield takeLatest(UPDATE_CONTACT_DETAILS, updateContactDetails);
  yield takeLatest(UPDATE_PASSWORD, updatePassword);
  yield takeLatest(FETCH_DEFAULT_CARD, fetchDefaultCard);
  yield takeLatest(UPDATE_DEFAULT_CARD, updateDefaultCard);
  yield takeLatest(FETCH_COUPLES_INFO, fetchCouplesInfo);
  yield takeLatest(CREATE_GUARDIANS, createGuardians);
  yield takeLatest(FETCH_GUARDIANS, fetchGuardians);
}
