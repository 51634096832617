/*
 *
 * LanguageProvider reducer
 *
 */

import { fromJS } from 'immutable';

import {
  CHANGE_LOCALE,
  GET_LANGUAGES,
  GET_LANGUAGES_ERROR,
  GET_LANGUAGES_SUCCESS,
} from './constants';
import { DEFAULT_LOCALE } from '../../i18n';

export const initialState = fromJS({
  locale: DEFAULT_LOCALE,
  error: false,
  languages: [],
  languagesLoading: false,
});

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', action.locale);
    case GET_LANGUAGES:
      return state.set('languagesLoading', true);
    case GET_LANGUAGES_SUCCESS:
      return state
        .set('languagesLoading', false)
        .set('languages', action.response);
    case GET_LANGUAGES_ERROR:
      return state
        .set('languagesLoading', false)
        .set('error', action.error.message);
    default:
      return state;
  }
}

export default languageProviderReducer;
