/*
 *
 * Dashboard reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_ERROR,
  FETCH_PRACTITIONER_SUCCESS,
  FETCH_UPCOMING_SESSIONS,
  FETCH_UPCOMING_SESSIONS_ERROR,
  FETCH_UPCOMING_SESSIONS_SUCCESS,
  FETCH_ONBOARDING,
  FETCH_ONBOARDING_SUCCESS,
  FETCH_ONBOARDING_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FETCH_COUPLES_INFO,
  FETCH_COUPLES_INFO_SUCCESS,
  FETCH_COUPLES_INFO_ERROR,
  FETCH_CARE_TEAM,
  FETCH_CARE_TEAM_SUCCESS,
  FETCH_CARE_TEAM_ERROR,
} from './constants';

export const initialState = fromJS({
  company: {},
  practitioner: {
    full_name: '',
    avatar: {
      url: null,
    },
    qualifications: '',
  },
  upcoming: [],
  onboarding: {},
  error: false,
  loading: false,
  couplesInfo: {},
  careTeam: {},
});

function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ONBOARDING:
      return state.set('loading', true);
    case FETCH_ONBOARDING_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_ONBOARDING_SUCCESS:
      return state
        .set('loading', false)
        .set('onboarding', fromJS(action.onboarding));
    case FETCH_COMPANY:
      return state.set('loading', true);
    case FETCH_COMPANY_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_COMPANY_SUCCESS:
      return state.set('loading', false).mergeDeep({ company: action.company });
    case FETCH_PRACTITIONER:
      return state.set('loading', true);
    case FETCH_PRACTITIONER_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_PRACTITIONER_SUCCESS:
      return state
        .set('loading', false)
        .set('practitioner', fromJS(action.practitioner));
    case FETCH_UPCOMING_SESSIONS:
      return state.set('loading', true);
    case FETCH_UPCOMING_SESSIONS_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_UPCOMING_SESSIONS_SUCCESS:
      return state
        .set('loading', false)
        .set('upcoming', fromJS(action.upcomingSessions));
    case CHANGE_PASSWORD:
      return state.set('loading', true);
    case CHANGE_PASSWORD_ERROR:
      return state.set('loading', false).set('error', action.error);
    case CHANGE_PASSWORD_SUCCESS:
      return state.set('loading', false);
    case FETCH_COUPLES_INFO:
      return state.set('loading', true).set('error', false);
    case FETCH_COUPLES_INFO_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_COUPLES_INFO_SUCCESS:
      return state
        .set('loading', false)
        .set('couplesInfo', fromJS(action.couplesInfo));
    case FETCH_CARE_TEAM:
      return state.set('loading', true).set('error', false);
    case FETCH_CARE_TEAM_ERROR:
      return state.set('loading', false).set('error', action.error);
    case FETCH_CARE_TEAM_SUCCESS:
      return state
        .set('loading', false)
        .set('careTeam', fromJS(action.careTeam));
    default:
      return state;
  }
}

export default dashboardReducer;
