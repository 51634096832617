import { defineMessages } from 'react-intl';

export const scope = 'app.components.Header';

export default defineMessages({
  dashboardLink: {
    id: `${scope}.dashboard.link`,
    defaultMessage: 'Dashboard',
  },
  myPractitionerLink: {
    id: `${scope}.myPractitioner.link`,
    defaultMessage: 'My Care Team',
  },
  appointmentsLink: {
    id: `${scope}.appointments.link`,
    defaultMessage: 'Appointments',
  },
  preferencesLink: {
    id: `${scope}.preferences.link`,
    defaultMessage: 'Preferences',
  },
  supportLink: {
    id: `${scope}.support.link`,
    defaultMessage: 'Support',
  },
  advisoryServices: {
    id: `${scope}.advisoryServices`,
    defaultMessage: 'Advisory Services',
  },
  coachingServices: {
    id: `${scope}.coachingServices`,
    defaultMessage: 'Coaching Services',
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: 'Log Out',
  },
  timeNow: {
    id: `${scope}.timeNow`,
    defaultMessage: 'Time now',
  },
  modify: {
    id: `${scope}.modify`,
    defaultMessage: 'Modify',
  },
  selectYourTimezone: {
    id: `${scope}.selectYourTimezone`,
    defaultMessage: 'Select your timezone',
  },
  selectTimezone: {
    id: `${scope}.selectTimezone`,
    defaultMessage: 'Select timezone',
  },
  updateTimezone: {
    id: `${scope}.updateTimezone`,
    defaultMessage: 'Update timezone',
  },
});
