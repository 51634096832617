import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import NavBar from './NavBar';
import NavHalf from './NavHalf';
import NavSide from './NavSide';
import Avatar from './Avatar';
import NavigationLogo from './NavigationLogo';
import UserInfo from './UserInfo';
import NavigationLinks from './NavigationLinks';
import NavSideLinks from './NavSideLinks';
import Hamburger from './Hamburger';
import Overlay from './Overlay';
import 'wicg-inert';

const NoAvatar = styled.div`
  width: 23px;
  height: 23px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: #ecf2f4;
`;

/* eslint-disable react/prefer-stateless-function */
class Header extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = { navSideOpen: false, dropDownOpen: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.navSideOpen !== this.state.navSideOpen) {
      if (!this.state.navSideOpen) {
        document.getElementById('app').inert = false;
      }
    }
  }

  toggleNavSide = () => {
    this.setState(prevState => ({
      navSideOpen: !prevState.navSideOpen,
    }));
  };

  toggleDropDown = () => {
    this.setState(prevState => ({
      dropDownOpen: !prevState.dropDownOpen,
    }));
  };

  render() {
    const {
      location: { pathname },
      user,
      newMessageInfo,
      handleSignOut,
      localeObject,
      timezoneObject,
    } = this.props;
    const fullName = `${user.get('first_name')} ${user.get('last_name')}`;
    const preferredName = user.get('preferred_name');
    const b2bUser = user.getIn(['company', 'name']);
    const avatarUrl = user.getIn(['avatar', 'url']);

    if (
      pathname.match(/^\/match|confirmation|two_factor|partner_registration/)
    ) {
      return <div />;
    }
    return (
      <div>
        <Overlay
          open={this.state.navSideOpen}
          toggleNavSide={this.toggleNavSide}
        />
        {this.state.navSideOpen && (
          <NavSide
            open={this.state.navSideOpen}
            toggleNavSide={this.toggleNavSide}
            className="navigation-side"
          >
            <NavSideLinks
              open={this.state.navSideOpen}
              toggleNavSide={this.toggleNavSide}
              newMessageInfo={newMessageInfo}
              handleSignOut={handleSignOut}
              localeObject={localeObject}
              timezoneObject={timezoneObject}
              userName={preferredName || fullName}
              b2bUser={b2bUser}
              user={user}
            />
          </NavSide>
        )}
        <NavBar id="navBar">
          <NavHalf className="navigation-top" role="banner">
            <NavigationLogo />
            <UserInfo
              userName={preferredName || fullName}
              Avatar={
                avatarUrl ? (
                  <Avatar src={`${avatarUrl}`} alt="avatar" />
                ) : (
                  <NoAvatar />
                )
              }
              toggleDropDown={this.toggleDropDown}
              open={this.state.dropDownOpen}
              newMessageInfo={newMessageInfo}
              handleSignOut={handleSignOut}
              localeObject={localeObject}
              timezoneObject={timezoneObject}
              b2bUser={b2bUser}
            />
            <Hamburger toggleNavSide={this.toggleNavSide} />
          </NavHalf>
          <NavHalf className="navigation-bottom" role="navigation">
            <NavigationLinks user={user} />
          </NavHalf>
        </NavBar>
      </div>
    );
  }
}

Header.propTypes = {
  location: PropTypes.object,
  user: PropTypes.instanceOf(Map),
  newMessageInfo: PropTypes.instanceOf(Map),
  handleSignOut: PropTypes.func.isRequired,
  localeObject: PropTypes.instanceOf(Map).isRequired,
  timezoneObject: PropTypes.instanceOf(Map).isRequired,
};

export default Header;
