import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import messages from './messages';

export const timezones = [
  { value: 'Pacific/Honolulu', label: <FormattedMessage {...messages.ht} /> },
  { value: 'America/Juneau', label: <FormattedMessage {...messages.akt} /> },
  { value: 'America/Vancouver', label: <FormattedMessage {...messages.pt} /> },
  { value: 'America/Edmonton', label: <FormattedMessage {...messages.mt} /> },
  { value: 'America/Winnipeg', label: <FormattedMessage {...messages.ct} /> },
  { value: 'America/Toronto', label: <FormattedMessage {...messages.et} /> },
  { value: 'America/Halifax', label: <FormattedMessage {...messages.at} /> },
  {
    value: 'America/St_Johns',
    label: <FormattedMessage {...messages.nt} />,
  },
  {
    value: 'America/Swift_Current',
    label: <FormattedMessage {...messages.st} />,
  },
  {
    value: 'MST',
    label: <FormattedMessage {...messages.mst} />,
  },
  {
    value: 'EST',
    label: <FormattedMessage {...messages.est} />,
  },
];

export const intlTimezones = [
  {
    label: '(UTC-12:00) International Date Line West',
    value: 'Pacific/Wallis',
  },
  {
    label: '(UTC-11:00) Coordinated Universal Time-11',
    value: 'Pacific/Pago_Pago',
  },
  {
    label: '(UTC-10:00) Hawaii',
    value: 'US/Hawaii',
  },
  {
    label: '(UTC-09:00) Alaska',
    value: 'US/Alaska',
  },
  {
    label: '(UTC-08:00) Pacific Time (US & Canada)',
    value: 'Canada/Pacific',
  },
  {
    label: '(UTC-07:00) Mountain Time (US & Canada)',
    value: 'US/Mountain',
  },
  {
    label: '(UTC-06:00) Central Time (US & Canada)',
    value: 'US/Central',
  },
  {
    label: '(UTC-05:00) Eastern Time (US & Canada)',
    value: 'US/Eastern',
  },
  {
    label: '(UTC-04:30) Caracas',
    value: 'America/Caracas',
  },
  {
    label: '(UTC-04:00) Atlantic Time (Canada)',
    value: 'Canada/Atlantic',
  },
  {
    label: '(UTC-03:30) Newfoundland',
    value: 'Canada/Newfoundland',
  },
  {
    label: '(UTC-03:00) Greenland',
    value: 'America/Godthab',
  },
  {
    label: '(UTC-02:00) Mid-Atlantic - Old',
    value: 'America/Noronha',
  },
  {
    label: '(UTC-01:00) Azores',
    value: 'Atlantic/Azores',
  },
  {
    label: '(UTC) Coordinated Universal Time',
    value: 'Etc/Greenwich',
  },
  {
    label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'Europe/Amsterdam',
  },
  {
    label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: 'Europe/Helsinki',
  },
  {
    label: '(UTC+03:00) Nairobi',
    value: 'Africa/Nairobi',
  },
  {
    label: '(UTC+03:30) Tehran',
    value: 'Asia/Tehran',
  },
  {
    label: '(UTC+04:00) Abu Dhabi, Muscat',
    value: 'Asia/Muscat',
  },
  {
    label: '(UTC+04:30) Kabul',
    value: 'Asia/Kabul',
  },
  {
    label: '(UTC+05:00) Ashgabat, Tashkent',
    value: 'Asia/Tashkent',
  },
  {
    label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    value: 'Asia/Kolkata',
  },
  {
    label: '(UTC+05:45) Kathmandu',
    value: 'Asia/Katmandu',
  },
  {
    label: '(UTC+06:00) Ekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  {
    label: '(UTC+06:30) Yangon (Rangoon)',
    value: 'Asia/Rangoon',
  },
  {
    label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    value: 'Asia/Hong_Kong',
  },
  {
    label: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    value: 'Asia/Tokyo',
  },
  {
    label: '(UTC+09:30) Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: '(UTC+10:00) Canberra, Melbourne, Sydney',
    value: 'Australia/Melbourne',
  },
  {
    label: '(UTC+11:00) Vladivostok',
    value: 'Asia/Vladivostok',
  },
  {
    label: '(UTC+12:00) Auckland, Wellington',
    value: 'Pacific/Auckland',
  },
  {
    label: '(UTC+13:00) Samoa',
    value: 'Pacific/Samoa',
  },
];

export const getTimezoneLabel = value => {
  let label = value;

  for (let index = 0; index < timezones.length; index += 1) {
    if (timezones[index].value === value) {
      ({ label } = timezones[index]);
      return label;
    }
  }

  for (let index = 0; index < intlTimezones.length; index += 1) {
    if (intlTimezones[index].value === value) {
      ({ label } = intlTimezones[index]);
      return label;
    }
  }

  return label;
};

export function setMomentTimezone(timezone) {
  moment.tz.setDefault(timezone);
}
