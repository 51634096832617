import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

const DropzoneInput = props => (
  <div>
    <Dropzone
      multiple={false}
      accept={props.accept}
      onDrop={filesToUpload => {
        props.onChange(filesToUpload);
      }}
      style={{}}
    >
      {props.Component}
    </Dropzone>
  </div>
);

DropzoneInput.propTypes = {
  Component: PropTypes.node,
  accept: PropTypes.string,
  onChange: PropTypes.func,
};

export default DropzoneInput;
