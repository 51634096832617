/**
 *
 * Panel
 *
 */

import styled from 'styled-components';
import { tabletSize, phoneSize } from '../../global-styles';

const Panel = styled.div`
  :first-of-type {
    background-color: #ffffff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 66%;

    @media (max-width: ${tabletSize}) {
      border-radius: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      width: 100%;
    }
  }

  :last-of-type {
    background-color: #fafafa;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 34%;

    @media (max-width: ${tabletSize}) {
      border-radius: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      width: 100%;
    }
  }

  h1 {
    margin-left: 60px;
    margin-top: 60px;
    @media (max-width: ${phoneSize}) {
      margin-left: 20px;
      margin-top: 40px;
    }
  }
`;

export default Panel;
