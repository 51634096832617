/*
 * LoadingPanel Messages
 *
 * This contains all the text for the LoadingPanel component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.LoadingPanel';

export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading...',
  },
});
